import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {forceView} from "../ForceConnector";
import {Auth} from "../../../user/auth/Auth";

import {Analytics} from "../../../GoogleAnalytics";

import "./c-f-print-styles.css"
import {doRestCall} from "../../../AppUtils";
import CardsPrint from "./CardsPrint";

const ForcePrint = () => {

    Analytics("/crusade/force/print", "Crusade Force Print")

    let {forceId} = useParams();

    const [loading, setLoading] = useState(true)
    const [force, setForce] = useState({});
    const [cards, setCards] = useState([]);

    const [battleScars, setBattleScars] = useState([])

    useEffect(() => {
        doRestCall('/codex/crusade/battlescars', 'get', null, null,
            (response) => setBattleScars(response.body))
    }, [force])

    useEffect(() => {
        loadForce()
    }, [])

    const loadForce = () => {
        doRestCall('/crusade/force/' + forceId + '/view', 'get', null, null,
            (response) => {
                setForce(response.body)
                setCards(response.body.cards)
            })
    }

    const columns = [{
        title: 'Name', dataIndex: 'name', key: 'name',
    }, {
        title: 'Points', dataIndex: 'points', key: 'points',
    }, {
        title: 'CrusadePoints', dataIndex: 'crusadePoints', key: 'crusadePoints',
    }, {
        title: 'Mark of Greatness', dataIndex: 'markOfGreatness', key: 'markOfGreatness',
    }, {
        title: 'Survived', dataIndex: 'survived', key: 'survived',
    }, {
        title: 'Enemy Units Destroyed', dataIndex: 'enemyUnitsDestroyed', key: 'enemyUnitsDestroyed',
    }

    ];

    return (
        <>
            <Auth/>

            <div className="c-f-view-create-game-section mt-5">
                <div className="c-f-view-container">
                    <div className="c-f-view-create-game-header mb-5">
                        <h2>{force.name}</h2>
                    </div>
                    <div className="c-f-view-create-game-form">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-10 ">
                                <div className="form mb-5  ">
                                    <div className="row mb-5 d-flex justify-content-between ">
                                        <div className="col-md-2 ">
                                            <div className="mb-3">
                                                <label className="form-label c-f-print-text-center">Supply Limit</label>
                                                <p className="c-f-print-text-center">{force.supplyLimit}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-print-text-center">Supply Used</label>
                                                <p className="c-f-print-text-center">{force.supplyUsed}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-print-text-center">Battle Tally</label>
                                                <p className="c-f-print-text-center">{force.battleTally}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-print-text-center">Victories</label>
                                                <p className="c-f-print-text-center">{force.victories}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-print-text-center">Requisition
                                                    Points</label>
                                                <p className="c-f-print-text-center">{force.reqPoints}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <table className="c-f-view-vtable">
                                        <thead className="c-f-view-vtable-head">
                                        <tr className="c-f-view-vtable-row">
                                            <th className="c-f-view-vtable-header">Name</th>
                                            <th className="c-f-view-vtable-header">Points</th>
                                            <th className="c-f-view-vtable-header">Crusade Points</th>
                                            <th className="c-f-view-vtable-header">Mark of Greatness</th>
                                            <th className="c-f-view-vtable-header">Survived</th>
                                        </tr>
                                        </thead>
                                        <tbody className="c-f-view-vtable-body">
                                        {force?.cards?.map(card =>
                                            <tr className="c-f-view-vtable-row">
                                                <td className="c-f-view-vtable-cell c-f-view-vtable-cell-name"><p>{card.name}</p></td>
                                                <td className="c-f-view-vtable-cell c-f-view-vtable-cell-15"><p>{card.points}</p></td>
                                                <td className="c-f-view-vtable-cell c-f-view-vtable-cell-15"><p>{card.crusadePoints}</p></td>
                                                <td className="c-f-view-vtable-cell c-f-view-vtable-cell-15"><i className="fa-regular fa-square" /></td>
                                                <td className="c-f-view-vtable-cell c-f-view-vtable-cell-15"><i className="fa-regular fa-square" /></td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    {force.notes && <div className="mb-4 mt-4">
                                        <div className="row">
                                            <div className="col-md-3 c-f-view-text-left">
                                                <label className="form-label">Notes: </label>
                                                <p>{force.notes}</p>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>

                                <CardsPrint cards={force?.cards} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForcePrint;

