import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import PickRandomMissionButton from "./PickRandomMissionButton";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import PickRandomRuleButton from "./PickRandomRuleButton";
import PickRule from "./PickRule";

const GameRule = ({gameId, gameMasterId}) => {


    const {currentUser} = useCurrentUserContext();

    const [rule, setRule] = useState();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    }, [currentUser, gameMasterId])

    function load() {
        doRestCall("/anonymous/rule/game/" + gameId, "get", null, null,
            (response) => setRule(response.body));
    }

    useEffect(() => {
        if (gameId !== undefined && gameId !== null) {
            load();
        }
    }, [gameId])

    return <>
        {!rule && isEditable &&
            <div className="row c-c-hq-row">
                <div className="col-lg-8">
                    <div className="g-v-mission">
                        <h6>Rule</h6>
                        <PickRule gameId={gameId} gameMasterId={gameMasterId} reload={load} selectedRule={rule}/>
                        <PickRandomRuleButton gameId={gameId} gameMasterId={gameMasterId} reload={load}/>
                    </div>
                </div>
            </div>
        }
        {rule &&
            <div className="row c-c-hq-row">
                <div className="col-lg-8">
                    <div className="g-v-mission">
                        <h6>Rule</h6>
                        <PickRule gameId={gameId} gameMasterId={gameMasterId} reload={load} selectedRule={rule}/>
                        <PickRandomRuleButton gameId={gameId} gameMasterId={gameMasterId} reload={load}/>
                    </div>
                    <div className="c-c-hq-mission-row">
                        <div>
                            <div className="g-v-mission-name">{rule.name}</div>
                            <div className="g-v-mission-brief">
                                <pre>{rule.brief}</pre>
                            </div>
                            <div className="g-v-mission-text">
                                <pre>{rule.text}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}

export default GameRule;