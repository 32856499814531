import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import moment from "moment/moment";
import StageCreate from "./StageCreated";
import StageRegStart from "./StageRegStart";
import StageRegEnd from "./StageRegEnd";
import StageStart from "./StageStart";
import StageEnd from "./StageEnd";
import StageRounds from "./StageRounds";

const Stages = ({tournamentId, setSelectedRound}) => {

    const [stages, setStages] = useState([]);

    const [currentStage, setCurrentStage] = useState()

    const [now, setNow] = useState(moment())

    useEffect(() => {
        setNow(moment())
    }, [tournamentId]);

    function loadStatus() {
        doRestCallAnonymous('/anonymous/tournament/' + tournamentId + "/status", 'get', null, null,
            (response) => {
                setCurrentStage(response.body.status)
            })
    }

    useEffect(() => {
        if (tournamentId !== undefined && tournamentId !== null) {
            loadStatus();
        }
    }, [tournamentId]);

    useEffect(() => {
        if (tournamentId !== undefined && tournamentId !== null) {
            doRestCallAnonymous('/anonymous/tournament/' + tournamentId + "/stages", 'get', null, null,
                (response) => {
                    setStages(response.body.stages);
                })
        }
    }, [tournamentId])

    return <>
        <div className="col-sm-4 col-5">
            <ul>
                <StageCreate currentStage={currentStage} stages={stages} />
                <StageRegStart tournamentId={tournamentId} currentStage={currentStage} stages={stages}  />
                <StageRegEnd tournamentId={tournamentId} currentStage={currentStage} stages={stages}  />
                <StageStart tournamentId={tournamentId}
                            currentStage={currentStage}
                            stages={stages}
                            onStagesChange={loadStatus}  />
                <StageRounds tournamentId={tournamentId}
                             currentStage={currentStage}
                             stages={stages}
                             setSelectedRound={setSelectedRound} />
                <StageEnd tournamentId={tournamentId} currentStage={currentStage} stages={stages}  />
            </ul>
        </div>
    </>
}

export default Stages;