import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {doRestCall} from "../../../AppUtils";
import Spinner from "../../../_components/Spinner";
import Factions from "./Factions";

const RegistrationOpen = ({tournamentId, onRegistrationUpdate }) => {

    const [loading, setLoading] = useState(false)
    const [selectedFaction, setSelectedFaction] = useState()
    const [universe, setUniverse] = useState()

    useEffect(() => {

        if (tournamentId !== null && tournamentId !== undefined) {
            doRestCall("/tournament/" + tournamentId + "/universe", 'get', null, null,
                (response) => setUniverse(response.body.universe))
        }

    }, [tournamentId]);


    function register() {
        setLoading(true)
        doRestCall('/tournament/' + tournamentId + '/register' , 'post', null, {
                faction: selectedFaction
            },
            (response) => {
                onRegistrationUpdate()
            },
            null,
            () => {
                setLoading(false)
            })
    }

    function onFactionSelect(e) {
        setSelectedFaction(e)
    }

    return <>
            <div className="t-vv-join-info-ch">
                <Factions universe={universe} setSelectedFaction={onFactionSelect} />
                <button className="t-vv-registration-button" onClick={() => {register()}}>Register</button>
            </div>
    </>
}

export default RegistrationOpen;