import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const Adjustment = ({adjustmentId, description, value}) => {

    const [d, setD] = useState("");
    const [id, setId] = useState("");
    const [v, setV] = useState("");

    useEffect(() => {
        setD(description)
        setId(adjustmentId)
        setV(value)
    }, [description, id, value])

    return (
        <>
            <div className="r-view-adjustment-row">
                <div className="r-view-adjustment-description">
                    {d}
                </div>
                <div className="r-view-adjustment-value">
                    {v}
                </div>
            </div>
        </>
    );
};

export default Adjustment;

