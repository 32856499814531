import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";


const Prices = ({prices}) => {

    return (
        <>
            <p className="r-manage-unit-prices" key={uuidv4()}>
                Price: {Object.entries(prices).map(([key, value]) => (
                <>{key}x{value}</>
            )).reduce((prev, curr) => [prev, ', ', curr])}
            </p>

        </>
    );
};

export default Prices;

