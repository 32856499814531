import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";
import Adjustment from "./Adjustment";


const Adjustments = ({rosterId, adjustments}) => {

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(adjustments)
    }, [adjustments]);

    return (
        <>
            <div className="r-view-adjustments">
                Adjustments:
                {list?.map(adjustment => <Adjustment
                    rosterId={rosterId}
                    value={adjustment?.value}
                    adjustmentId={adjustment?.id}
                    description={adjustment?.description}  />
                )}
            </div>
        </>
    );
};

export default Adjustments;

