import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const PlayerFaction = ({faction, gameMasterId, gameId, playerId, universe}) => {

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    const [factions, setFactions] = useState([]);

    const [selectedFaction, setSelectedFaction] = useState();


    useEffect(() => {
        if (faction !== null && faction !== undefined) {
            setSelectedFaction(faction);
        }
    }, [faction])


    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id || playerId === currentUser?.id ))
    },[currentUser, gameMasterId])

    useEffect(() => {
        if (universe !== undefined && universe !== null) {
            setFactions(null)
            doRestCall( '/codex/factions', 'get', {
                universe: universe,
            }, null, (response) => setFactions(response.body) )
        }
    }, [universe]);

    function onChange(value) {
        doRestCall('/game/update/participant/faction', 'put', null, {
            gameId: gameId,
            participantId: playerId,
            faction: value
        })
    }

    return <>
        {!isEditable && <div className="g-v-c-g-r-cell">{faction}</div> }

        {isEditable && !universe && <div className="g-v-c-g-r-cell">Please select universe</div> }

        {isEditable && universe && <select className="g-v-info-players-select" onChange={(e) => onChange(e.target.value)}>
            <option selected={selectedFaction === null || selectedFaction === undefined} value="">None</option>
            {factions?.sort()
                .map(f =>
                <option selected={f === selectedFaction} value={f} >{f}</option>)
            }
        </select> }

    </>
}

export default PlayerFaction;