import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";
import {usersAll} from "../../../user/UserConnector";
import {Select} from "antd";
import moment from "moment";

const Notes = ({preSelectedNotes, gameId}) => {

    const [defaultValue, setDefaultValue] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (preSelectedNotes !== undefined && preSelectedNotes !== null) {
            setDefaultValue(preSelectedNotes);
        }
    }, [preSelectedNotes])


    const onChange = (value) => {
        setDefaultValue(value);

        doRestCall('/game/update/notes', 'put', null, {
            gameId: gameId,
            notes: value
        })
    };

    return <>
        <Spinner spinning={loading}>
            <textarea
                defaultValue={defaultValue}
                name="" id="" className="form-control"
                rows="4"
                onChange={(event) => onChange(event.target.value)}></textarea>
        </Spinner>
    </>
}

export default Notes;