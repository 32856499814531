import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const PickRandomDeployButton = ({gameId, gameMasterId, reload}) => {

    const {currentUser} = useCurrentUserContext();

    const [deploy, setDeploy] = useState();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser])

    useEffect(() => {
        if (deploy !== undefined && deploy !== null && gameId !== undefined && gameId !== null) {
            assign()
        }
    }, [deploy])

    function assign() {
        doRestCall("/game/deploy/assign", 'put', null, {
            gameId: gameId,
            deployId: deploy.id
        },
            () => reload())
    }

    function pickRandom() {
        doRestCall("/deploy/random", "get", null, null,
            (response) => {
                setDeploy(response.body)
            });
    }

    return (
        <>
            {isEditable && <button className="g-v-info-pick-random-rule-button" onClick={(e) => pickRandom()}> Pick Random Deploy</button> }
        </>
    );
};

export default PickRandomDeployButton;