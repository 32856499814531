import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";
import {usersAll} from "../../../user/UserConnector";
import {Select} from "antd";
import moment from "moment";

const Date = ({preSelectedDate, gameId}) => {

    const [defaultValue, setDefaultValue] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (preSelectedDate !== undefined && preSelectedDate !== null) {
            setDefaultValue(preSelectedDate);
        }
    }, [preSelectedDate])


    const onChange = (value) => {
        setDefaultValue(value);

        doRestCall('/game/update/date', 'put', null, {
            gameId: gameId,
            date:  value + 'T00:00:00'
        })
    };

    return <>
        <Spinner spinning={loading}>
            <input type="date"
                   defaultValue={defaultValue && moment(defaultValue).format('yyyy-MM-DD')}
                   className="form-control"
                   placeholder="Date"
                   onChange={(event) => onChange(event.target.value) } />
        </Spinner>
    </>
}

export default Date;