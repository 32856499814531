import React, {useEffect, useState} from "react";
import {
    Button, Modal, Space,
} from "antd";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import "./styles-t-all.css"
import {Analytics} from "../../GoogleAnalytics";
import Status from "./components/Status";
import {doRestCall, doRestCallAnonymous} from "../../AppUtils";
import Spinner from "../../_components/Spinner";

const TournamentsAnonymous = () => {

    Analytics("/tournaments/my", "My Tournaments (Anon)")

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [tournaments, setTournaments] = useState([]);

    useEffect(() => {
        load()
    }, [])

    function onLoadSuccess(response) {
        setTournaments(response.body)
        setLoading(false)
    }

    const load = () => {
        setLoading(true)
        doRestCallAnonymous( '/anonymous/tournaments', 'get',
            {
                created: false,
                master: false,
                participated: false,
                all: true
            }, null, onLoadSuccess, null,
            () => setLoading(false))
    }

    function view(id) {
        navigate('/tournament/' + id + '/view')
    }

    return (
        <>
            <Spinner isLoading={loading}>
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-11">
                        <div className="t-all-vtable-container">
                            <table className="t-all-vtable">
                                <thead className="t-all-vtable-head">
                                <tr className="t-all-vtable-row">
                                    <th className="t-all-vtable-header">Name</th>
                                    <th className="t-all-vtable-header">Universe</th>
                                    <th className="t-all-vtable-header">Participants</th>
                                    <th className="t-all-vtable-header">Status</th>
                                </tr>
                                </thead>
                                <tbody className="t-all-vtable-body">
                                {tournaments?.map(t =>
                                    <tr className="t-all-vtable-row">
                                        <td className="t-all-vtable-cell t-all-vtable-cell-t-name" onClick={() => view(t.id)}>{t.name}</td>
                                        <td className="t-all-vtable-cell">{t.universe}</td>
                                        <td className="t-all-vtable-cell">{t.participantsCount} / {t.participantsLimit ? t.participantsLimit : "no limit"}</td>
                                        <td className="t-all-vtable-cell"><Status status={t.status} /></td>
                                    </tr>
                                )}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </Spinner>
        </>
    );
};

export default TournamentsAnonymous;

