import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";
import {usersAll} from "../../../user/UserConnector";
import {Select} from "antd";

const GameMaster = ({preSelectedGameMasterId, setSelectedGameMasterId, gameId, preSelectedGameMasterName}) => {

    const [defaultValue, setDefaultValue] = useState(preSelectedGameMasterId);
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (preSelectedGameMasterName !== undefined && preSelectedGameMasterName !== null) {
            setDefaultValue(preSelectedGameMasterName);
        }
    }, [preSelectedGameMasterName, users])

    useEffect(() => {
        setLoading(true)
        doRestCall('/users/all', 'get', null, null,
            (response) => {
                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        name: response.body[i].username,
                        value: response.body[i].id,
                        label: response.body[i].username,
                    }
                }
                setUsers(response.body)
            }, null, () => setLoading(false));
    }, [])

    const onChange = (value) => {
       // setSelectedGameMasterId(value)
        setDefaultValue(value);

        doRestCall('/game/update/gamemaster', 'put', null, {
            gameId: gameId,
            gameMasterId: value,
        })
    };

    const filterGameMasters = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <>
        <Spinner spinning={loading}>
            <Select
                value={defaultValue}
                defaultValue={defaultValue}
                className="select"
                options={users}
                data-cy="gameMaster"
                showSearch
                filterOption={filterGameMasters}
                onChange={(value) => onChange(value)}/>
        </Spinner>
    </>
}

export default GameMaster;