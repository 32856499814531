import React, {useEffect, useState} from "react";


import {useCurrentUserContext} from "../../user/CurrentUserContext";

import "./styles-t-vv.css"
import Stages from "./components/Stages";
import Results from "./components/Results";
import Games from "./components/Games";
import Missions from "./components/Missions";
import Rules from "./components/Rules";
import Deploys from "./components/Deploys";
import Layouts from "./components/Layouts";
import Players from "./components/Players";
import {doRestCall, doRestCallAnonymous} from "../../AppUtils";
import {useParams} from "react-router-dom";
import Info from "./components/Info";
import {TournamentContextProvider, useTournamentContext} from "./TournamentContext";

const TournamentViewV2 = () => {

    let {tournamentId} = useParams();

    const {currentUser} = useCurrentUserContext();
    const {setTournamentId} = useTournamentContext();

    useEffect(() => {
        setTournamentId(tournamentId);
    }, [tournamentId]);

    const [selectedRound, setSelectedRound] = useState(1)

    return (
        <>
            <TournamentContextProvider currentUser={currentUser} >
            <div className="container c-c-hq-container">
                <div className="c-c-hq-name"><h2 className="">Tournament Details</h2></div>

                <div className="m-5">
                    <div className="row c-c-hq-row">
                        <Stages tournamentId={tournamentId} setSelectedRound={setSelectedRound} />
                        <Players tournamentId={tournamentId} />
                        <Results tournamentId={tournamentId} />
                    </div>
                    <div className="row c-c-hq-row">
                        <Games tournamentId={tournamentId} round={selectedRound} />
                    </div>
                    <div className="row c-c-hq-row ">
                        <Info tournamentId={tournamentId} />
                    </div>
                    <div className="row c-c-hq-row ">
                        <Missions />
                        <Rules />
                        <Deploys />
                        <Layouts  />
                    </div>
                </div>
            </div>
            </TournamentContextProvider>
        </>
    );
};

export default TournamentViewV2;

