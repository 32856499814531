import React, {useEffect, useState} from "react";

import {Analytics} from "../GoogleAnalytics";
import {useNavigate} from "react-router-dom";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import {doRestCall} from "../AppUtils";

import "./styles-l.css"
import Grid from "./Grid";

const Home = () => {

    Analytics("/landing", "Landing")

    const navigate = useNavigate();
    const { currentUser } = useCurrentUserContext();

    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [error, setError] = useState()

    function reset() {
        setName("")
        setEmail("")
        setMessage("")
        setError("")
    }

    useEffect(() => {
        if (currentUser !== undefined && currentUser !== null) {
            navigate('/home')
        }
    }, [])

    useEffect(() => {
        reset()
    }, [])

    return (
        <>
            {/*

                            <Grid rows={5} cols={5} />

            */}
            <div className="container mt-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10">
                        <div className="mb-5">
                            <div className="l-display-4 l-text-center mb-2">Dive into the Ultimate Warhammer 40k
                                experience
                            </div>
                            <div className="l-subheading-landing l-text-center mb-2">Revolutionize your Warhammer 40k games
                                and tournaments with our advanced management system. Join a community of passionate
                                players, access exclusive resources and enhance your gameplay.
                            </div>
                        </div>

                        <div className="mb-5 l-text-center">
                            <button onClick={() => navigate('/user/registration')} className="l-primary-btn">Join Now <i
                                className="fa-solid fa-arrow-right l-button-join-now"></i></button>
                        </div>
                        {/*
                        <div className="l-text-center">
                            <img src="https://placehold.co/1000x600" alt=""/>
                        </div>
*/}

                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-3">
                        <div className="l-card l-card-border">
                            <h2 className="l-icon-plus"><i className="fa-regular fa-star"></i> 01</h2>
                            <div className="l-landing-details-head">Simplified Tournament Setup</div>
                            <div className="l-landing-details">Streamline the organization and execution
                                of your tournaments with user-friendly tools and customization options.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="l-card l-card-border">
                            <h2 className="l-icon-plus"><i className="fa-regular fa-star"></i> 02</h2>
                            <div className="l-landing-details-head">Advanced Player Stats</div>
                            <div className="l-landing-details">Track player performance with detailed
                                statistics to enhance competitiveness and engagement.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="l-card l-card-border">
                            <h2 className="l-icon-plus"><i className="fa-regular fa-star"></i> 03</h2>
                            <div className="l-landing-details-head">Real-time Match Reporting</div>
                            <div className="l-landing-details">Enjoy seamless tracking of live match updates and
                                progress with real-time reporting that ensures transparent play and accurate rankings.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-10">
                        <div className="mb-5">
                            <div className="l-display-6 l-text-center mb-2">Battle Features</div>
                            <div className="l-display-4 l-text-center mb-2">Standout Aspects</div>
                            <div className="l-subheading-landing l-text-center mb-2">Manage your Warhammer 40k games and
                                tournaments effortlessly with our cutting edge system, designed for players and
                                organizers to ensure a smooth, exciting experience from start to finish.
                            </div>
                        </div>
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <div className="l-card l-card-no-border">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Game Tracker</div>
                                        <div className="l-landing-details l-text-center">Keep meticulous records of all
                                            your Warhammer 40K matches, including outcomes, scores, and player statistics.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="l-card l-card-no-border">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Tournament Generator</div>
                                        <div className="l-landing-details l-text-center">Create balanced and competitive
                                            tournaments with ease, accommodating various formats and participants numbers.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="l-card l-card-no-border">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Leaderboards Display</div>
                                        <div className="l-landing-details l-text-center">Showcase player rankings and
                                            achievements in real-time, adding excitement and motivation for all participants.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="l-card l-card-no-border">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Event Scheduler</div>
                                        <div className="l-landing-details l-text-center">Plan and coordinate events
                                            seamlessly, ensuring that nothing clashes and everything runs on time.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="l-card l-card-no-border">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Community Hub</div>
                                        <div className="l-landing-details l-text-center">Connect with fellow Warhammer 40K
                                            enthusiasts, share strategies, and stay updated with the latest news and events.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="l-card l-card-no-border">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Custom Maps</div>
                                        <div className="l-landing-details l-text-center">Design and share custom battlefield
                                            setups to enhance gameplay and challenge your opponents with unique terrains,
                                            layouts, and strategic possibilities.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-5">
                                    <div className="l-display-6  mb-3">Tournament Tracker</div>
                                    <div className="l-display-4  mb-3">Manage your Warhammer 40k Tournaments
                                        with ease
                                    </div>
                                    <div className="l-subheading-landing mb-3">Effortlessly organize and
                                        oversee your Warhammer 40K tournaments.
                                        Use our powerful tools to track matches, scores, and player stats,
                                        ensuring your events run smoothly and professionally
                                    </div>
                                    <ul className="fa-ul">
                                        <li className="l-landing-details"><i className="fa-solid fa-check"></i> Seamlessly create tournament brackets
                                        </li>
                                        <li className="l-landing-details"><i className="fa-solid fa-check"></i> Instantly update match results</li>
                                        <li className="l-landing-details"><i className="fa-solid fa-check"></i> Automate player rankings and pairings
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {/*
                                <div className="l-text-center">
                                    <img src="https://placehold.co/500x500" alt=""/>
                                </div>
*/}
                            </div>
                        </div>
                    </div>
                </div>



                {/*
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-10">
                        <div className="l-stats-container">
                            <div className="row l-text-center">
                                <div className="col-md-3">
                                    <div className="l-stats-number mb-3">42+</div>
                                    <div>lorem ipsum</div>
                                </div>
                                <div className="col-md-3">
                                    <div className="l-stats-number mb-3">42+</div>
                                    <div>lorem ipsum</div>
                                </div>
                                <div className="col-md-3">
                                    <div className="l-stats-number mb-3">42+</div>
                                    <div>lorem ipsum</div>
                                </div>
                                <div className="col-md-3">
                                    <div className="l-stats-number mb-3">42+</div>
                                    <div>lorem ipsum</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
*/}




                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-10">
                        <div className="mb-5">
                            <div className="l-display-4 l-text-center mb-3">Warhammer 40K-Centic Services</div>
                            <div className="l-display-6 l-text-center mb-3">Tailored solutions for managing and enhancing
                                your Warhammer 40K gaming events
                            </div>
                        </div>
                        <div className="mb-5">
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <div className="l-card">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Tournament Scheduling</div>
                                        <div className="l-landing-details">Easily plan and manage your
                                            Warhammer
                                            40K tournaments with our initiative
                                            scheduling tools
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="l-card">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Player Ranking System</div>
                                        <div className="l-landing-details">Keep track of player's performance
                                            and
                                            ranks across multiple events for a
                                            competitive edge
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="l-card">
                                        <div className="l-text-center"><i className="fa-regular fa-star fa-2x"></i></div>
                                        <div className="l-landing-details-head l-text-center">Event Reporting</div>
                                        <div className="l-landing-details">Generate comprehensive tournament summaries
                                            with detailed match outcomes, player statistics, and event highlights to
                                            streamline analysis and improve future planning.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/*
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-10">
                        <div className="mb-5">
                            <div className="display-4 l-text-center mb-3">FAQs</div>
                            <div className="display-6 l-text-center mb-3">Common Questions on Tournament Management</div>
                        </div>
                        <div className="mb-5">
                            <div className="l-accordion" id="accordionExample" >
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="l-accordion-button accordion-button-style"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                            How do I create a new tournament in the system?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                         data-bs-parent="#accordionExample">
                                        <div className="l-accordion-body" >
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia dolorum
                                            modi dolor minima ullam tempora aperiam tenetur, quos vitae assumenda
                                            voluptatem accusantium beatae inventore corrupti ad animi consequatur quam
                                            sunt.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="l-accordion-button"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="true" aria-controls="collapseOne">
                                            Can I track player statistics and match outcomes?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                         data-bs-parent="#accordionExample">
                                        <div className="l-accordion-body" >
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia dolorum
                                            modi dolor minima ullam tempora aperiam tenetur, quos vitae assumenda
                                            voluptatem accusantium beatae inventore corrupti ad animi consequatur quam
                                            sunt.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="l-accordion-button"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                aria-expanded="true" aria-controls="collapseOne">
                                            How do I join a tournament as a player?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                         data-bs-parent="#accordionExample">
                                        <div className="l-accordion-body" >
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia dolorum
                                            modi dolor minima ullam tempora aperiam tenetur, quos vitae assumenda
                                            voluptatem accusantium beatae inventore corrupti ad animi consequatur quam
                                            sunt.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="l-accordion-button"
                                                type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                aria-expanded="true" aria-controls="collapseOne">
                                            Is theere a fee to use this tournament management system?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse"
                                         data-bs-parent="#accordionExample">
                                        <div className="l-accordion-body" >
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officia dolorum
                                            modi dolor minima ullam tempora aperiam tenetur, quos vitae assumenda
                                            voluptatem accusantium beatae inventore corrupti ad animi consequatur quam
                                            sunt.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
*/}


                {/*
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-10">
                        <div className="l-contact-section mt-5">
                            <div className="l-contact-container">
                                <div className="l-contact-form">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-6">
                                            <div className="form mb-5">
                                                <div>{error}</div>
                                                <div className="mb-3">
                                                    <label className="l-form-label">Name</label>
                                                    <input type="text" placeholder="Name" onBlur={(e) => setName(e.target.value)}/>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="l-form-label">Email</label>
                                                    <input type="text" placeholder="Email" onBlur={(e) => setName(e.target.value)} />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="l-form-label">Message</label>
                                                    <textarea className="l-form-control" rows="4" id="" onBlur={(e) => setName(e.target.value)}></textarea>
                                                </div>
                                            </div>
                                            <div className="l-primary-btn" onClick={() => {
                                                if (name === undefined || name === "") {
                                                    setError("Name is required")
                                                }
                                               if (email === undefined || email === "") {
                                                    setError("Email is required")
                                                }
                                               if (message === undefined || message === "") {
                                                    setError("Message is required")
                                                }

                                                if (name && email && message) {
                                                    doRestCall('/feedback', 'post', null, {
                                                        name: name,
                                                        email: email,
                                                        text: message})
                                                    reset()
                                                }

                                            }}>Send Message</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
*/}

            </div>

            {/*
                            <Grid rows={10} cols={10} />
            */}
        </>
    )
};

export default Home;

