import React, {useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Form,
    Input,
    Select,
    Table
} from "antd";
import {useNavigate} from "react-router-dom";
import {createForce} from "../ForceConnector";
import {Auth} from "../../../user/auth/Auth";
import {notificationCreateFailure, notificationCreateForceSuccess} from "../ForceNotifications";
import {Analytics} from "../../../GoogleAnalytics";

const ForceCreate = () => {

    Analytics("/crusade/force/create", "Crusade Force Create")

    const navigate = useNavigate();

    const [form] = Form.useForm();

    const save = (force) => {
        createForce(force)
            .then(response => {
                    if (response.valid) {
                        notificationCreateForceSuccess()
                        navigate('/crusade/force/' + response.body.id + '/view')
                    } else {
                        notificationCreateFailure()
                    }
                }
            )
            .catch(error => {
                console.log(error)
            })
    }

    function onFinish(values) {
        save(values)
    }

    return (
        <>
            <Auth/>

            <p className="forceCreateTitle">Force Create</p>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}
                  className="forceCreateForm"
                  >

                <Form.Item
                    label="Name"
                    name="name"
                    className="forceCreateInput"  >
                    <Input data-cy="name" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="forceCreateButton" data-cy="submit">
                        Submit
                    </Button>
                </Form.Item>

            </Form>
        </>
    );
};

export default ForceCreate;

