import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const AddPlayer = ({gameMasterId, gameId, reload, playersCount}) => {

    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    const [selectedPlayer, setSelectedPlayer] = useState();

    useEffect(() => {
        setSelectedPlayer(null);
    }, [])

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    useEffect(() => {
        setLoading(true)
        doRestCall('/users/all', 'get', null, null,
            (response) => {
                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        name: response.body[i].username,
                        value: response.body[i].id,
                        label: response.body[i].username,
                    }
                }
                setUsers(response.body)
            }, null, () => setLoading(false));
    }, [])

    function onChange(userId) {
        setSelectedPlayer(userId)
    }

    function onAdd(userId) {
        doRestCall('/game/update/participant/add', 'put', null, {
            gameId: gameId,
            participantId: userId,
        },
            () => reload())
    }

    return <>
        <h6>Add Player</h6>
        <div className="g-v-info-cell g-v-info-text"></div>
        <div>
            <select className="g-v-info-players-select" onChange={(e) => onChange(e.target.value)}>
                <option value="">None</option>
                {users?.map(user =>
                    <option value={user.id} >{user.name}</option>)
                }
            </select>
            <button disabled={playersCount === 2} className="g-v-info-add-player-button" onClick={() => onAdd(selectedPlayer)}>Add</button>
        </div>
    </>
}

export default AddPlayer;