import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";
import moment from "moment";
import {TournamentStage} from "../../config";
import Calendar from "react-calendar";
import StageDate from "./StageDate";

const StageCreate = ({tournamentId, currentStage, stages}) => {
    const navigate = useNavigate();

    const [stage, setStage] = useState();

    useEffect(() => {
        let filter = stages?.filter(s => s.name === TournamentStage.CREATED )[0];
        setStage(filter)
    }, [stages])

    function markActive() {
        return TournamentStage.CREATED === currentStage
    }

    function markPassed() {
        return [TournamentStage.CREATED, TournamentStage.REGISTRATION_CLOSED, TournamentStage.REGISTRATION_OPENED,
            TournamentStage.STARTED, TournamentStage.ENDED].includes(currentStage)
    }

    return <>
        <li className={markPassed() ? "t-vv-state-past" : "t-vv-state-future"}>
            <div className={markActive() ? "t-vv-state-cell-active" : "t-vv-state-cell"}>{stage?.label}</div>
            <div className="t-vv-state-cell">{stage && stage?.dateTime && moment(stage.dateTime).format('DD MMM YYYY h:mm a')}</div>
        </li>
    </>
}

export default StageCreate;