import React, {useEffect, useState} from 'react';

const Layout = ({name, filepath}) => {
    const [isHovering, setIsHovering] = useState(false);

    return <>
        <div
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{position: 'relative', display: 'inline-block'}}>
            {name}
            {isHovering && (<div className="t-vv-game-layout-preview">
                <div className="title">{name}</div>
                <img className="layout" src={filepath} alt=""/>
            </div>)}
        </div>
    </>
}

export default Layout;