import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import Calendar from "react-calendar";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useTournamentContext} from "../TournamentContext";

const StageDate = ({dateTime, tournamentId, masterId, onValueChange}) => {

    const [isEdit, setIsEdit] = useState(false)
    const [value, setValue] = useState()

    const {isEditable} = useTournamentContext();

    useEffect(() => {
        setValue(dateTime)
    }, [dateTime])

    function onChange(value) {
        onValueChange(value)
        setValue(moment(value).format('yyyy-MM-DD'))
    }

    return <>
        {!isEditable && value && <div className="t-vv-info-cell">{moment(value).format('DD MMM YYYY')} </div>}

        {isEditable && !isEdit && value &&
            <div className="t-vv-info-cell" onClick={() => setIsEdit(true)}>{moment(value).format('DD MMM YYYY')}
                <i className="fa-solid fa-calendar t-vv-info-calendar" onClick={() => setIsEdit(true)}></i>
            </div>}

        {isEditable && !isEdit && !value && <div className="t-vv-info-cell" onClick={() => setIsEdit(true)}>Select Date
            <i className="fa-solid fa-calendar t-vv-info-calendar" onClick={() => setIsEdit(true)}></i>
        </div>}

        {isEditable && isEdit && <span>
            {isEdit &&
                <button className="t-vv-stage-calendar-close-button" onClick={() => setIsEdit(false)}>Cancel</button>}
            <Calendar
                defaultValue={dateTime}
                defaultView={"month"}
                onChange={(value) => {
                    onChange(value);
                    setIsEdit(false);
                }}
            />
            </span>}
    </>
}

export default StageDate;