import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCallAnonymous} from "../../../AppUtils";
import Rule from "./Rule";

const Rules = ({}) => {

    const [rules, setRules] = useState([]);

    useEffect(() => {
        doRestCallAnonymous("/anonymous/rules", 'get', null, null,
            (response) => setRules(response.body.rules));
    }, [])

    return <>
        <div className="col-sm-3 col-5">
            <h6>Rules</h6>

            {rules?.map((rule) => <div key={rule.id} className="t-vv-text t-vv-row">
                    <div className="t-vv-cell"><Rule name={rule.name} brief={rule.brief} text={rule.text} /></div>
                </div>
            )}

        </div>
    </>
}

export default Rules;