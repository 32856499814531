import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";
import {usersAll} from "../../../user/UserConnector";
import {Select} from "antd";
import moment from "moment";

const Date = ({preSelectedName, gameId}) => {

    const [defaultValue, setDefaultValue] = useState();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (preSelectedName !== undefined && preSelectedName !== null) {
            setDefaultValue(preSelectedName);
        }
    }, [preSelectedName])


    const onChange = (value) => {
        setDefaultValue(value);

        doRestCall('/game/update/name', 'put', null, {
            gameId: gameId,
            name:  value
        })
    };

    return <>
        <Spinner spinning={loading}>
            <input type="text"
                   defaultValue={preSelectedName}
                   placeholder="Name"
                   onChange={(event) => onChange(event.target.value)}/>
        </Spinner>
    </>
}

export default Date;