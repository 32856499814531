import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isAnonymous, isUserAuthenticated} from "../user/auth/Auth";
import {useCurrentUserContext} from "../user/CurrentUserContext";
import logo from "../_components/images/logo-svg.svg";

import "./styles-menu.css"

const AnonymousUserMainMenu = () => {
    const navigate = useNavigate();

    const [current, setCurrent] = useState('home');
    const {currentUser} = useCurrentUserContext();

    const [home, setHome] = useState(true);
    const [tournaments, setTournaments] = useState(false);
    const [games, setGames] = useState(false);

    function onHome(e) {
        e.preventDefault();
        setHome(true)
        setTournaments(false)
        navigate("/home")
    }

    function onGames(e) {
        e.preventDefault();
        setHome(false)
        setGames(true)
        setTournaments(false)
        navigate("/games")
    }

    function onTournaments(e) {
        e.preventDefault();
        setHome(false)
        setGames(false)
        setTournaments(true)
        navigate("/tournaments")
    }

    return (
        <>
            {isAnonymous(currentUser) &&
                < nav className="m-navbar">
                    <div ><img  className="m-logo" src={logo} alt=""/></div>
                    <ul className="m-menu">
                        {/*
                        <li><a href="#">Features</a></li>
                        <li><a href="#about">FAQ</a></li>
                        <li><a href="#">Contact</a></li>
*/}
                        <li><a href="#"
                               className={home ? 'm-menu-active' : ''}
                               onClick={(e) => onHome(e)}>Home</a></li>
{/*
                        <li><a href="#"
                               className={games ? 'm-menu-active' : ''}
                               onClick={(e) => onGames(e)}>Games</a></li>
*/}

                        <li><a href="#"
                               className={tournaments ? 'm-menu-active' : ''}
                               onClick={(e) => onTournaments(e)}>Tournaments</a></li>

                    </ul>
                    <div className="m-user-info">
                        <button onClick={() => navigate('/user/login')}
                                className="m-button m-button-signin">Sign In
                        </button>
                        <button onClick={() => navigate('/user/registration')}
                                className="m-button m-button-join-the-fight">Join the Fight</button>
                    </div>
                </nav>
            }
        </>
    )
}

export default AnonymousUserMainMenu;

