import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCallAnonymous} from "../../../AppUtils";
import {TournamentStage} from "../../config";

const Results = ({tournamentId}) => {

    const [results, setResults] = useState([])

    const [currentStage, setCurrentStage] = useState()

    useEffect(() => {
        if (tournamentId !== undefined && tournamentId !== null) {
            doRestCallAnonymous('/anonymous/tournament/' + tournamentId + "/status", 'get', null, null,
                (response) => {
                    setCurrentStage(response.body.status)
                })
        }
    }, [tournamentId]);

    useEffect(() => {
        if (tournamentId !== null && tournamentId !== undefined) {
            doRestCallAnonymous('/anonymous/tournament/' + tournamentId + '/results', 'get', null, null,
                (response) => setResults(response.body.results))
        }
    }, [tournamentId])


    if ([TournamentStage.STARTED, TournamentStage.ENDED].includes(currentStage)) {
        return <>
            <div className="col-lg-8">
{/*
                <h6>Players Results</h6>
*/}
                <div className="t-vv-players ">
                    <div className="t-vv-players-header">Position</div>
                    <div className="t-vv-players-header">Name</div>
                    <div className="t-vv-players-header">Wins</div>
                    <div className="t-vv-players-header">Draws</div>
                    <div className="t-vv-players-header">Losses</div>
                </div>

                {results?.sort((a, b) => (a.position - b.position))
                    .map((result) => <div className="t-vv-players ">
                        <div className="t-vv-players-position">{result.position}</div>
                        <div className="t-vv-players-name">{result.username} {result.faction && <span>({result.faction})</span>}</div>
                        <div className="t-vv-players-score">{result.wins}</div>
                        <div className="t-vv-players-score">{result.draws}</div>
                        <div className="t-vv-players-score">{result.losses}</div>
                    </div>)}
            </div>
        </>
    } else {
        return <></>
    }

}

export default Results;