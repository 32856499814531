import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {doRestCall} from "../../AppUtils";
import {Auth} from "../../user/auth/Auth";
import Unit from "./components/Unit";

import "./r-manage-styles.css"
import Factions from "./components/Factions";
import {Analytics} from "../../GoogleAnalytics";
import Adjustments from "./components/Adjustments";

const RosterManage = () => {

    Analytics("/roster/manage", "Roster Manage")

    let {rosterId} = useParams();

    const [roster, setRoster] = useState({});
    const [characterUnits, setCharacterUnits] = useState([]);
    const [battlelineUnits, setBattlelineUnits] = useState([]);
    const [vehicleUnits, setVehicleUnits] = useState([]);
    const [otherUnits, setOtherUnits] = useState([]);
    const [adjustments, setAdjustments] = useState([]);

    const [summary, setSummary] = useState(0)
    const [blink, setBlink] = useState("")
    const navigate = useNavigate();

    const [filterName, setFilterName] = useState("");
    const [hideWeapons, setHideWeapons] = useState(false);
    const [showSelected, setShowSelected] = useState(false);

    const [errors, setErrors] = useState([]);

    useEffect(()=> {
        reloadRoster()
    }, [])

    function navigateBack() {
        navigate("/roster/" + rosterId + "/view");
    }

    function reloadRoster() {
        doRestCall('/roster/' + rosterId + '/manage', 'get', null, null,
            (response) => {
                setRoster(response.body)
                setCharacterUnits(response.body.units?.characterUnits)
                setBattlelineUnits(response.body.units?.battlelineUnits)
                setVehicleUnits(response.body.units?.vehicleUnits)
                setOtherUnits(response.body.units?.otherUnits)
                updateSummary(response.body.summary);
                setErrors(response.errors);
                setAdjustments(response.body.adjustments);
            });
    }

    function updateSummary(summary) {
        setSummary(summary)
        setBlink("blink")
        setTimeout(()=> setBlink(""), 300)
    }

    useEffect(() => {
        if (filterName === undefined || filterName === null || filterName === "") {
            setCharacterUnits(roster?.units?.characterUnits)
            setBattlelineUnits(roster?.units?.battlelineUnits)
            setVehicleUnits(roster?.units?.vehicleUnits)
            setOtherUnits(roster?.units?.otherUnits)
        } else {
            setCharacterUnits(roster?.units?.characterUnits?.filter(unit => unit.name.toUpperCase().includes(filterName.toUpperCase())))
            setBattlelineUnits(roster?.units?.battlelineUnits?.filter(unit => unit.name.toUpperCase().includes(filterName.toUpperCase())))
            setVehicleUnits(roster?.units?.vehicleUnits?.filter(unit => unit.name.toUpperCase().includes(filterName.toUpperCase())))
            setOtherUnits(roster?.units?.otherUnits?.filter(unit => unit.name.toUpperCase().includes(filterName.toUpperCase())))
        }
    }, [filterName])

    useEffect(() => {
        if (showSelected) {
            setCharacterUnits(roster?.units?.characterUnits?.filter(unit => unit.selected))
            setBattlelineUnits(roster?.units?.battlelineUnits?.filter(unit => true === unit.selected))
            setVehicleUnits(roster?.units?.vehicleUnits?.filter(unit => true === unit.selected))
            setOtherUnits(roster?.units?.otherUnits?.filter(unit => true === unit.selected))
        } else {
            setCharacterUnits(roster?.units?.characterUnits)
            setBattlelineUnits(roster?.units?.battlelineUnits)
            setVehicleUnits(roster?.units?.vehicleUnits)
            setOtherUnits(roster?.units?.otherUnits)
        }
    }, [showSelected])

    return (
        <>
            <Auth />

            <div className=" mt-5">
                <h2 className="r-manage-name">{roster?.name}</h2>
                <div className="r-manage-summary">
                    Total: <p className={"r-manage-summary-number " + blink}>{summary}</p>
                    {errors && errors.length > 0 && <div className="r-manage-errors">Errors: {errors.map(error => error.error).join(", ")}</div>}
                </div>

                <div className="r-manage-roster-container">

                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">

                            <div className="r-manage-filters justify-content-center">
                                <div>
                                    <Factions reloadRoster={reloadRoster} rosterId={rosterId} preSelectedFaction={roster?.faction} />
                                </div>
                                <div className="r-manage-search">
                                    Search by name: <input value={filterName}
                                                           className="r-manage-search-input"
                                                           onChange={(e) => setFilterName(e.target.value)}/>
                                    <button type="button" className="r-manage-search-input-clear-button" onClick={() => setFilterName("")}>✖</button>
                                </div>
                                <div className="r-manage-hideweapons">
                                    Hide weapons:
                                    {hideWeapons && <i className="fa fa-check r-manage-hideweapons-select" onClick={(e) => setHideWeapons(false)}></i>}
                                    {!hideWeapons && <i className="fa-regular fa-square r-manage-hideweapons-selectbox" onClick={(e) => setHideWeapons(true)}></i>}
                                </div>
                                <div className="r-manage-showselected">
                                    Show selected:
                                    {showSelected && <i className="fa fa-check r-manage-showselected-select" onClick={(e) => setShowSelected(false)}></i>}
                                    {!showSelected && <i className="fa-regular fa-square r-manage-showselected-selectbox" onClick={(e) => setShowSelected(true)}></i>}
                                </div>
                            </div>

                            <div className="d-flex justify-content-center mb-2 ">
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Character</div>
                                        <br/>
                                        {characterUnits?.sort((a, b) => a.name.localeCompare(b.name))
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit} setSummary={updateSummary} reloadRoster={reloadRoster} hideWeapons={hideWeapons} /><br/></div> )}
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Battleline</div>
                                        <br/>
                                        {battlelineUnits?.sort((a, b) => a.name.localeCompare(b.name))
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit} setSummary={updateSummary} reloadRoster={reloadRoster} hideWeapons={hideWeapons} /><br/></div>)}
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Vehicle</div>
                                        <br/>
                                        {vehicleUnits?.sort((a, b) => a.name.localeCompare(b.name))
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit} setSummary={updateSummary} reloadRoster={reloadRoster} hideWeapons={hideWeapons} /><br/></div>)}
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="r-view-unit-type">Other</div>
                                        <br/>
                                        {otherUnits?.sort((a, b) => a.name.localeCompare(b.name))
                                            .map(unit => <div><Unit rosterId={rosterId} unit={unit} setSummary={updateSummary} reloadRoster={reloadRoster} hideWeapons={hideWeapons} /><br/></div>)}
                                    </div>
                            </div>

                            <Adjustments rosterId={rosterId} adjustments={adjustments} reloadRoster={reloadRoster} />

                            <div className="row d-flex justify-content-between">
                                <div className="col-auto">
                                    <div className="c-f-view-tlist-btn" onClick={() => navigateBack()}>Back</div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default RosterManage;

