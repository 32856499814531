import React, {useEffect, useState} from "react";

import "./styles-t-view.css"

import Participants from "./components/Participants";
import Status from "./components/Status";
import Details from "./components/Details";
import DetailsAnonymous from "./components/DetailsAnonymous";

const TournamentRegistrationOpenAnonymous = ({tournament}) => {

    const [players, setPlayers] = useState([])

    useEffect(() => {
        setPlayers(tournament.players)
    }, [tournament]);

    return (
        <>
            <div className="t-v-container-ch">
                <h1 className="t-v-heading-m-ch">{tournament.name}</h1>

                <Status status={tournament.status}/>

                <div className="mb-3 text-center ">
                    <span className="t-v-alerts-open"><i className="fa-solid fa-circle-exclamation" ></i> Registration is open</span>
                </div>

                <DetailsAnonymous
                    status={tournament.status}
                    regStartDate={tournament.registrationStartDate}
                    regEndDate={tournament.registrationEndDate}
                    startDate={tournament.startDate}
                    endDate={tournament.endDate}
                    master={tournament.tournamentMaster}
                    universe={tournament.universe}
                    tournamentId={tournament.id}
                    onTournamentUpdate={(tournament) => { setPlayers(tournament.players) }}
                    participantsLimit={tournament.participantsLimit}
                    participantsCount={players?.length}
                    registrationOpen={true}
                />

            </div>

            <Participants tournamentId={tournament.id} tournamentPlayers={players}/>
        </>
    );
};

export default TournamentRegistrationOpenAnonymous;

