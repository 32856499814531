import ReactGA from "react-ga4";
import axios from "axios";
import {env, restRoute} from "./AppUtils";
import {DataDogView} from "./DataDog";



export function Analytics(page, title) {
    if (env() === 'PROD') {
        ReactGA.send({hitType: "pageview", page: page, title: title});
        DataDogView(title);
    }
}
