import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";

const Factions = ({universe, setSelectedFaction}) => {
    const [factions, setFactions] = useState([]);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (universe !== undefined && universe !== null) {
            setFactions(null)
            doRestCall( '/codex/factions', 'get', {
                universe: universe,
            }, null, (response) => setFactions(response.body) )
        }
    }, [universe]);


    return <>
        <select className="t-vv-select" onChange={(e) => setSelectedFaction(e.target.value)} >
            <option key={"none"} value="">None</option>
            {factions?.sort()
                .map(f =>
                    <option value={f} >{f}</option>)
            }
        </select>
    </>
}

export default Factions;