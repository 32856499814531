import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const PickRule = ({gameId, gameMasterId, reload, selectedRule}) => {

    const {currentUser} = useCurrentUserContext();

    const [rules, setRules] = useState();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    }, [currentUser])

    useEffect(() => {
        if (gameId !== null && gameId !== undefined) {
            doRestCall("/anonymous/rules", "get", null, null,
                (response) => setRules(response.body.rules));
        }
    }, [gameId])

    function assign(ruleId) {
        doRestCall("/game/rule/assign", 'put', null, {
                gameId: gameId,
                ruleId: ruleId
            },
            () => reload())
    }

    return (
        <>
            { isEditable && <select className="g-v-info-rule-select" onChange={(e) => assign(e.target.value)}>
                <option value="">None</option>
                {rules?.sort((a, b) => a.name.localeCompare(b.name))
                    .map(rule => <option selected={selectedRule?.id === rule.id} value={rule.id}>{rule.name}</option>)
                }
            </select> }
        </>
    );
};

export default PickRule;