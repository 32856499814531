import React, {useEffect, useState} from 'react';
import PlayerName from "./PlayerName";
import PlayerFaction from "./PlayerFaction";
import PlayerScore from "./PlayerScore";
import PlayerWinner from "./PlayerWinner";
import PlayerCrusadeRoster from "./PlayerCrusadeRoster";
import PlayerCrusadeGameResults from "./PlayerCrusadeGameResults";
import DeletePlayerButton from "./DeletePlayerButton";
import PlayerRoster from "./PlayerRoster";

const Player = ({player, gameSettings, gameId, gameMasterId, reload, universe}) => {

    const [isCrusade, setIsCrusade] = useState(false);

    useEffect(() => {
        if (gameSettings !== null && gameSettings !== undefined) {
            setIsCrusade(gameSettings.includes("CRUSADE"));
        }
    }, [gameSettings])

    return <>
        <div className="col-lg-8 g-v-player" >
            <h6>Player</h6>
            <div className="g-v-text g-v-c-g-r-row"><div className="g-v-c-g-r-cell">Name</div>
                <PlayerName username={player.username} />
            </div>
            <div className="g-v-text g-v-c-g-r-row"><div className="g-v-c-g-r-cell">Faction</div>
                <PlayerFaction faction={player.faction}
                               gameId={gameId}
                               gameMasterId={gameMasterId}
                               playerId={player.userId}
                               universe={universe}/>
            </div>
            <div className="g-v-text g-v-c-g-r-row"><div className="g-v-c-g-r-cell">Score</div>
                <PlayerScore score={player.score} gameId={gameId} gameMasterId={gameMasterId} playerId={player.userId} />
            </div>
            <div className="g-v-text g-v-c-g-r-row"><div className="g-v-c-g-r-cell">Winner</div>
                <PlayerWinner winner={player.winner} gameId={gameId} gameMasterId={gameMasterId} playerId={player.userId} />
            </div>
            {isCrusade && <div className="g-v-text g-v-c-g-r-row"><div className="g-v-c-g-r-cell">Crusade Roster</div>
                <PlayerCrusadeRoster playerId={player.userId} gameId={gameId} gameMasterId={gameMasterId} />
            </div> }
            {!isCrusade && <div className="g-v-text g-v-c-g-r-row"><div className="g-v-c-g-r-cell">Roster</div>
                <PlayerRoster playerId={player.userId} gameId={gameId} gameMasterId={gameMasterId} />
            </div> }
            {isCrusade && <PlayerCrusadeGameResults gameId={gameId} playerId={player.userId} gameMasterId={gameMasterId} /> }
            <div className="g-v-c-g-r-row">
                <DeletePlayerButton gameId={gameId} gameMasterId={gameMasterId} reload={reload} playerId={player.userId} />
            </div>
        </div>
    </>
}

export default Player;