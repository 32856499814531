import React, {useEffect, useState} from "react";
import {Auth, isAdminAuthenticated, isAnonymous, isUserAuthenticated} from "../../user/auth/Auth";
import "./styles-t-all.css"
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import TournamentsAnonymous from "./TournamentsAnonymous";
import TournamentsRegistered from "./TournamentsRegistered";

const Tournaments = () => {

    const {currentUser} = useCurrentUserContext();

    return (
        <>
            {isAnonymous(currentUser) && <TournamentsAnonymous />}
            {(isUserAuthenticated(currentUser) || isAdminAuthenticated(currentUser)) && <TournamentsRegistered />}
        </>
    );
};

export default Tournaments;

