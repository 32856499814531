import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";

const Universes = ({preSelectedUniverse, setSelectedUniverse, gameId}) => {
    const [universeOptions, setUniverseOptions] = useState([])
    const [universes, setUniverses] = useState([]);
    const [defaultValue, setDefaultValue] = useState(preSelectedUniverse);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (preSelectedUniverse !== undefined && preSelectedUniverse !== null) {
            setDefaultValue(preSelectedUniverse);
            setSelectedUniverse(preSelectedUniverse);
        }
    }, [preSelectedUniverse]);

    useEffect(() => {
        if (universes !== undefined && universes !== null) {
            let temp = []
            for (let i = 0; i < universes.length; i++) {
                temp[i] = {
                    value: universes[i],
                    label: universes[i],
                }
            }
            setUniverseOptions(temp)
        }
    }, [universes]);


    useEffect(() => {
        setLoading(true)
        doRestCall( '/codex/universes', 'get', null, null,
            (response) => setUniverses(response.body), null, () => setLoading(false))
    }, []);

    const onUniverseChange = (value) => {
        setSelectedUniverse(value)
        setDefaultValue(value);

        doRestCall('/game/update/universe', 'put', null, {
            gameId: gameId,
            universe: value,
        })
    };

    return <>
        <Spinner spinning={loading}>
            <Select
                value={defaultValue}
                placeholder="Select universe"
                onChange={onUniverseChange}
                options={universeOptions}
                style={{width: 200}}
            />
        </Spinner>
    </>
}

export default Universes;