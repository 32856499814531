import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall, doRestCallAnonymous} from "../../../../AppUtils";
import Player from "./Player";
import AddPlayer from "./AddPlayer";

const PLayers = ({gameMasterId, gameId, gameSettings, universe}) => {

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    const [players, setPlayers] = useState([]);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    function load() {
        doRestCallAnonymous("/anonymous/game/" + gameId + "/players", "get", null, null,
            (response) => {
                setPlayers(response.body);
            })
    }

    useEffect(() => {
        if (gameId !== undefined && gameId !== null) {
            load();
        }
    }, [gameId])

    return <>
        {isEditable && <AddPlayer gameMasterId={gameMasterId} gameId={gameId} reload={load} playersCount={players?.length} /> }
        {players && players.sort((a, b) => a.username - b.username)
            .map((player) => <Player player={player}
                                                    gameMasterId={gameMasterId}
                                                    gameId={gameId}
                                                    gameSettings={gameSettings}
                                                    reload={load}
                                                    universe={universe} /> )}
    </>
}

export default PLayers;