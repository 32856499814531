import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCallAnonymous} from "../../../AppUtils";

const Rules = ({name, brief, text}) => {
    const [isHovering, setIsHovering] = useState(false);

    return <>
        <div
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{position: 'relative', display: 'inline-block'}}>
            {name}
            {isHovering && (<div className="t-vv-game-rule-preview">
                <div className="title">{name}</div>
                <pre className="brief">{brief}</pre>
                <pre className="text">{text}</pre>
            </div>)}
        </div>
    </>
}

export default Rules;