import React, {useEffect, useState} from "react";
import {
    Button, Modal, Space,
} from "antd";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import "./styles-t-all.css"
import {Analytics} from "../../GoogleAnalytics";
import Status from "./components/Status";
import {doRestCall} from "../../AppUtils";
import Spinner from "../../_components/Spinner";

const TournamentsRegistered = () => {

    Analytics("/tournaments/my", "My Tournaments")

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [tournaments, setTournaments] = useState([]);

    const [created, setCreated] = useState(false);
    const [master, setMaster] = useState(false);
    const [participated, setParticipated] = useState(false);
    const [all, setAll] = useState(true);

    const [isModalOpen, setModalOpen] = useState(false)
    const [newTournamentName, setNewTournamentName] = useState("")
    const [newTournamentError, setNewTournamentError] = useState()

    useEffect(() => {
        load()
    }, [])

    useEffect(() => {
        load()
    }, [created, master, participated, all])


    function onLoadSuccess(response) {
        setTournaments(response.body)
        setLoading(false)
    }

    const load = () => {
        setLoading(true)
        doRestCall( '/tournaments', 'get',
            {
                created: created,
                master: master,
                participated: participated,
                all: all
            }, null, onLoadSuccess, null,
            () => setLoading(false))

    }

    function view(id) {
        navigate('/tournament/' + id + '/view')
    }

    function onCreate(e) {
        e.preventDefault()
        setModalOpen(true)
    }

    function onOk() {
        if (newTournamentName === null || newTournamentName === undefined || newTournamentName === "") {
            setNewTournamentError("Name is required")
            return
        }

        doRestCall('/tournament/create', 'post', null, {
            name: newTournamentName
        }, (response) => {
            setNewTournamentName("")
            setNewTournamentError("")
            setModalOpen(false)
            navigate("/tournament/" + response.body.id + "/view")
        })
    }

    function onCancel() {
        setNewTournamentName("")
        setNewTournamentError("")
        setModalOpen(false)
    }

    function onCreated(e) {
        e.preventDefault();
        setAll(false)
        setParticipated(false)
        setMaster(false)
        setCreated(true)
    }

    function onMaster(e) {
        e.preventDefault();
        setAll(false)
        setParticipated(false)
        setMaster(true)
        setCreated(false)
    }

    function onParticipated(e) {
        e.preventDefault();
        setAll(false)
        setParticipated(true)
        setMaster(false)
        setCreated(false)
    }

    function onAll(e) {
        e.preventDefault();
        setAll(true)
        setParticipated(false)
        setMaster(false)
        setCreated(false)
    }

    return (
        <>
            <Modal title="Create Tournament" open={isModalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
                {newTournamentError && <p>{newTournamentError}</p>}
                <Space direction="horizontal">
                    <label>Name</label>
                    <input type="text" placeholder="Name" onChange={(e) => setNewTournamentName(e.target.value)}/>
                </Space>
            </Modal>

            <Spinner isLoading={loading}>
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-11">
                        <div className="row mb-3 justify-content-between">
                            <div className="col-auto">
                                <button className={all ? "g-all-tlist-btn-active" : "g-all-tlist-btn"}
                                        onClick={(e) => onAll(e)}>ALL
                                </button>
                                <button className={created ? "g-all-tlist-btn-active" : "g-all-tlist-btn"}
                                        onClick={(e) => onCreated(e)}>Created By Me
                                </button>
                                <button className={master ? "g-all-tlist-btn-active" : "g-all-tlist-btn"}
                                        onClick={(e) => onMaster(e)}>Im Master
                                </button>
                                <button className={participated ? "g-all-tlist-btn-active" : "g-all-tlist-btn"}
                                        onClick={(e) => onParticipated(e)}>Im Participating
                                </button>
                            </div>
                            <div className="col-auto">
                                <button className="t-all-tlist-btn"
                                onClick={(e) => onCreate(e)}>Create Tournament</button>
                            </div>
                        </div>

                        <div className="t-all-vtable-container">
                            <table className="t-all-vtable">
                                <thead className="t-all-vtable-head">
                                <tr className="t-all-vtable-row">
                                    <th className="t-all-vtable-header">Name</th>
                                    <th className="t-all-vtable-header">Universe</th>
                                    <th className="t-all-vtable-header">Participants</th>
                                    <th className="t-all-vtable-header">Status</th>
                                </tr>
                                </thead>
                                <tbody className="t-all-vtable-body">
                                {tournaments?.map(t =>
                                    <tr className="t-all-vtable-row">
                                        <td className="t-all-vtable-cell t-all-vtable-cell-t-name" onClick={() => view(t.id)}>{t.name}</td>
                                        <td className="t-all-vtable-cell">{t.universe}</td>
                                        <td className="t-all-vtable-cell">{t.participantsCount} / {t.participantsLimit ? t.participantsLimit : "no limit"}</td>
                                        <td className="t-all-vtable-cell"><Status status={t.status} /></td>
                                    </tr>
                                )}

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </Spinner>
        </>
    );
};

export default TournamentsRegistered;

