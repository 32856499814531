import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCallAnonymous} from "../../../AppUtils";
import Layout from "./Layout";

const Layouts = ({}) => {

    const [layouts, setLayouts] = useState([]);

    useEffect(() => {
        doRestCallAnonymous("/anonymous/layouts", 'get', null, null,
            (response) => setLayouts(response.body.layouts));
    }, [])

    return <>
        <div className="col-sm-3 col-5">
            <h6>Layouts</h6>

            {layouts?.map((layout) => <div key={layout.id} className="t-vv-text t-vv-row">
                    <div className="t-vv-cell"><Layout name={layout.name} filepath={layout.filepath} /></div>
                </div>
            )}

        </div>
    </>
}

export default Layouts;