import React, {useEffect, useState} from "react";
import {DatePicker, Modal, Space} from "antd";
import {doRestCall} from "../../../../../AppUtils";


const Players = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [modalAllianceName, setModalAllianceName] = useState()

    const [alliances, setAlliances] = useState([])

    function reload() {
        doRestCall('/campaignhq/alliance/all', 'get', null, null,
            (response) => {
                setAlliances(response.body);
            })
    }

    useEffect(() => {
        reload();
    }, [])

    function onCreate(e) {
        e.preventDefault()
        setIsModalOpen(true)

        setModalAllianceName(undefined)
    }

    function onOk() {
        setIsModalOpen(false)

        doRestCall('/campaignhq/alliance/create', 'post', null, {
            campaignHqId: "0",
            name: modalAllianceName,
        }, (response) => {
            setModalAllianceName(undefined)
            reload();
        })
    }

    function onCancel() {
        setIsModalOpen(false)

        setModalAllianceName(undefined)
    }

    return (
        <>
            <Modal title="Add Player" open={isModalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
                <Space direction="horizontal">
                    <label>Name</label>
                    <input type="text" defaultValue={modalAllianceName} placeholder="Name" onChange={(e) => setModalAllianceName(e.target.value)}/>
                </Space>
            </Modal>

            <div className="col-lg-8" >

                <div className="c-c-hq-players-title-name"><h6>Players</h6></div>
                <div className="c-c-hq-players-title-icon">
                    <i className="fa-solid fa-plus" onClick={(e) => onCreate(e)}></i>
                </div>

                <div className="c-c-hq-players ">
                    <div className="c-c-hq-players-name-header">Name</div>
                    <div className="c-c-hq-players-alliance-header">Alliance</div>
                    <div className="c-c-hq-players-role-header">Role</div>
                    <div className="c-c-hq-players-faction-header">Faction</div>
                    <div className="c-c-hq-players-timezone-header">TZ</div>
                    <div className="c-c-hq-players-score-header">Score</div>
                </div>
                <div className="c-c-hq-players ">
                    <div className="c-c-hq-players-name ">Alex</div>
                    <div className="c-c-hq-players-alliance ">Festering Pool</div>
                    <div className="c-c-hq-players-role ">Admin</div>
                    <div className="c-c-hq-players-faction ">Death Guard</div>
                    <div className="c-c-hq-players-timezone ">CST</div>
                    <div className="c-c-hq-players-score ">123</div>
                </div>
                <div className="c-c-hq-players ">
                    <div className="c-c-hq-players-name ">Stevo</div>
                    <div className="c-c-hq-players-alliance ">Festering Pool</div>
                    <div className="c-c-hq-players-role ">Player</div>
                    <div className="c-c-hq-players-faction ">Daemons</div>
                    <div className="c-c-hq-players-timezone ">CST</div>
                    <div className="c-c-hq-players-score ">456</div>
                </div>
                <div className="c-c-hq-players ">
                    <div className="c-c-hq-players-name ">Lucky</div>
                    <div className="c-c-hq-players-alliance ">Festering Pool</div>
                    <div className="c-c-hq-players-role ">Player</div>
                    <div className="c-c-hq-players-faction ">Chaos Knights</div>
                    <div className="c-c-hq-players-timezone ">GMT+1</div>
                    <div className="c-c-hq-players-score ">123</div>
                </div>
                <div className="c-c-hq-players ">
                    <div className="c-c-hq-players-name ">Fara</div>
                    <div className="c-c-hq-players-alliance ">NONE</div>
                    <div className="c-c-hq-players-role ">Player</div>
                    <div className="c-c-hq-players-faction ">Space Marines</div>
                    <div className="c-c-hq-players-timezone ">EST</div>
                    <div className="c-c-hq-players-score ">123</div>
                </div>
                <div className="c-c-hq-players ">
                    <div className="c-c-hq-players-name ">Velvetine</div>
                    <div className="c-c-hq-players-alliance ">Terran Born Imperials</div>
                    <div className="c-c-hq-players-role ">Player</div>
                    <div className="c-c-hq-players-faction ">Space Marines</div>
                    <div className="c-c-hq-players-timezone ">CST</div>
                    <div className="c-c-hq-players-score ">123</div>
                </div>
            </div>
        </>
    );
};

export default Players;

