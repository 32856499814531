import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const PickRandomMissionButton = ({gameId, gameMasterId, reload}) => {

    const {currentUser} = useCurrentUserContext();

    const [mission, setMission] = useState();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    useEffect(() => {
        if (mission !== undefined && mission !== null && gameId !== undefined && gameId !== null) {
            assign()
        }
    }, [mission])

    function assign() {
        doRestCall("/game/mission/assign", 'put', null, {
            gameId: gameId,
            missionId: mission.id
        },
            () => reload())
    }

    function pickRandom() {
        doRestCall("/mission/random", "get", null, null,
            (response) => {
                setMission(response.body)
            });
    }

    return (
        <>
            {isEditable && <button className="g-v-info-pick-random-mission-button" onClick={(e) => pickRandom()}> Pick Random Mission</button> }
        </>
    );
};

export default PickRandomMissionButton;