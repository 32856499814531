import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";

const GameNotes = ({notes, gameId, gameMasterId}) => {

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    const onChange = (value) => {
        doRestCall('/game/update/notes', 'put', null, {
            gameId: gameId,
            notes: value
        })
    };

    return <>
        {!isEditable && <div className="g-v-info-cell">{notes}</div> }

        {isEditable && <textarea rows="2" className="g-v-info-notes-input" defaultValue={notes} onBlur={(e) => onChange(e.target.value)} /> }
    </>
}

export default GameNotes;