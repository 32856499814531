import React, {useEffect, useState} from 'react';
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useTournamentContext} from "../TournamentContext";

const GameLayout = ({gameId}) => {
    const [layouts, setLayouts] = useState([])
    const [selectedLayout, setSelectedLayout] = useState()

    const {isEditable} = useTournamentContext();

    useEffect(() => {
        doRestCallAnonymous("/anonymous/layouts", 'get', null, null,
            (response) => setLayouts(response.body.layouts))
    }, [gameId])

    useEffect(() => {
        if (gameId !== null && gameId !== undefined) {
            doRestCallAnonymous("/anonymous/layout/game/" + gameId, 'get', null, null,
                (response) => setSelectedLayout(response.body))
        }
    }, [layouts])

    function assign(value) {
        doRestCall("/game/layout/assign", "put", null, {
            gameId: gameId,
            layoutId: value
        })
    }

    if (isEditable) {
        return <>
            <div className="c-c-hq-games-name">
                <select className="t-vv-select" onChange={(e) => assign(e.target.value) }>
                    <option value="">None</option>
                    {layouts?.sort((a, b) => a.name.localeCompare(b.name))
                        .map(layout => <option selected={layout.id === selectedLayout?.id} value={layout.id} >{layout.name}</option>)
                    }
                </select>
            </div>
        </>
    } else {
        return <>
            <div className="c-c-hq-games-name">
                {selectedLayout && selectedLayout.name}
            </div>
            </>
    }


}

export default GameLayout;