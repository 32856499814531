import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const PickRandomLayoutButton = ({gameId, gameMasterId, reload}) => {

    const {currentUser} = useCurrentUserContext();

    const [layout, setLayout] = useState();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser])

    useEffect(() => {
        if (layout !== undefined && layout !== null && gameId !== undefined && gameId !== null) {
            assign()
        }
    }, [layout])

    function assign() {
        doRestCall("/game/layout/assign", 'put', null, {
            gameId: gameId,
            layoutId: layout.id
        },
            () => reload())
    }

    function pickRandom() {
        doRestCall("/layout/random", "get", null, null,
            (response) => {
                setLayout(response.body)
            });
    }

    return (
        <>
            {isEditable && <button className="g-v-info-pick-random-rule-button" onClick={(e) => pickRandom()}> Pick Random Layout</button> }
        </>
    );
};

export default PickRandomLayoutButton;