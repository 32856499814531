import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import moment from "moment";
import {TournamentStage} from "../../config";
import Universe from "./Universe";
import Master from "./Master";
import {useTournamentContext} from "../TournamentContext";

const Info = ({tournamentId}) => {

    const {isEditable} = useTournamentContext();

    const [info, setInfo] = useState({})

    useEffect(() => {
        if (tournamentId !== null && tournamentId !== undefined) {
            doRestCallAnonymous('/anonymous/tournament/' + tournamentId + '/info', 'get', null, null,
                (response) => setInfo(response.body))
        }
    }, [tournamentId])

    function onLimitUpdate(value) {
        doRestCall('/tournament/update/userslimit', 'put', null, {
            tournamentId: tournamentId,
            usersLimit: value
        })
    }

    function onTablesUpdate(value) {
        doRestCall('/tournament/update/tables', 'put', null, {
            tournamentId: tournamentId,
            tables: value
        })
    }

    function onAddressUpdate(value) {
        doRestCall('/tournament/update/address', 'put', null, {
            tournamentId: tournamentId,
            address: value
        })
    }

    function onDiscordChannelUpdate(value) {
        doRestCall('/tournament/update/discordchannel', 'put', null, {
            tournamentId: tournamentId,
            discordChannel: value
        })
    }

    function onDiscordInviteUpdate(value) {
        doRestCall('/tournament/update/discordinvite', 'put', null, {
            tournamentId: tournamentId,
            discordInvite: value
        })
    }

    function onNameUpdate(value) {
        doRestCall('/tournament/update/name', 'put', null, {
            tournamentId: tournamentId,
            name: value
        })
    }

    return <>
        <div className="col-lg-12">
            <h6>Info</h6>
            {isEditable && <div className="t-vv-text t-vv-info-row">Name: <input type="number"
                                                                  defaultValue={info?.name}
                                                                  className="t-vv-info-input"
                                                                  onChange={(e) => onNameUpdate(e.target.value)} /></div> }
            {!isEditable && <div className="t-vv-text t-vv-info-row">Name: {info?.name}</div> }


            <div className="t-vv-text t-vv-info-row">Master: <Master tournamentId={tournamentId} masterId={info?.masterId} /> </div>

            <div className="t-vv-text t-vv-info-row">Universe: <Universe universe={info?.universe}
                                                                    masterId={info?.masterId}
                                                                    tournamentId={tournamentId}/>
            </div>

            {isEditable && <div className="t-vv-text t-vv-info-row">Limit: <input type="number"
                                                                   defaultValue={info?.usersLimit}
                                                                   className="t-vv-info-input-number"
            onChange={(e) => onLimitUpdate(e.target.value)} />
            </div> }
            {!isEditable && <div className="t-vv-text t-vv-info-row">Limit: {info?.usersLimit}</div>}


            {isEditable && <div className="t-vv-text t-vv-info-row">
                <div>Tables <input type="number"
                                   defaultValue={info?.tables}
                                   className="t-vv-info-input-number"
                                   onChange={(e) => onTablesUpdate(e.target.value)}/>
                </div>
            </div> }
            {!isEditable && <div className="t-vv-text t-vv-info-row">Tables: {info?.tables}</div>}

            {isEditable && <div className="t-vv-text t-vv-info-row">
                <div>Address</div>
                <div><textarea rows="2"
                               defaultValue={info?.address}
                               className="t-vv-info-area"
                               onChange={(e) => onAddressUpdate(e.target.value)}/></div>
            </div> }
            {!isEditable && <div className="t-vv-text t-vv-info-row">Address: {info?.address}</div> }


            {isEditable && <div className="t-vv-text t-vv-info-row">
                <div>Discord</div>
                <div><input defaultValue={info?.discordInvite}
                            className="t-vv-info-input"
                            onChange={(e) => onDiscordInviteUpdate(e.target.value)}/>
                </div>
            </div> }
            {!isEditable && <div className="t-vv-text t-vv-info-row">Discord: {info?.discordInvite}</div> }




            {/*
                <div>
                    <input defaultValue={info?.discordChannel}
                           className="t-vv-info-input"
                           onChange={(e) => onDiscordChannelUpdate(e.target.value)}
                    />
                </div>
*/}
        </div>

    </>
}

export default Info;