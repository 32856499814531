import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const PickMission = ({gameId, gameMasterId, reload, selectedMission}) => {

    const {currentUser} = useCurrentUserContext();

    const [missions, setMissions] = useState();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    }, [currentUser])

    useEffect(() => {
        if (gameId !== null && gameId !== undefined) {
            doRestCall("/anonymous/missions", "get", null, null,
                (response) => setMissions(response.body.missions));
        }
    }, [gameId])

    function assign(missionId) {
        doRestCall("/game/mission/assign", 'put', null, {
                gameId: gameId,
                missionId: missionId
            },
            () => reload())
    }

    return (
        <>
            { isEditable && <select className="g-v-info-mission-select" onChange={(e) => assign(e.target.value)}>
                <option value="">None</option>
                {missions?.sort((a, b) => a.name.localeCompare(b.name))
                    .map(mission => <option selected={selectedMission?.id === mission.id} value={mission.id}>{mission.name}</option>)
                }
            </select> }
        </>
    );
};

export default PickMission;