import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";
import {TournamentStage} from "../../config";
import moment from "moment";
import StageDate from "./StageDate";

const StageRegStart = ({tournamentId, currentStage, stages}) => {
    const navigate = useNavigate();

    const [stage, setStage] = useState();

    useEffect(() => {
        let filter = stages?.filter(s => s.name === TournamentStage.REGISTRATION_OPENED )[0];
        setStage(filter)
    }, [stages])

    function markActive() {
        return TournamentStage.REGISTRATION_OPENED === currentStage
    }

    function markPassed() {
        return [TournamentStage.REGISTRATION_OPENED, TournamentStage.REGISTRATION_CLOSED, TournamentStage.STARTED, TournamentStage.ENDED].includes(currentStage)
    }

    function onChange(value) {
        doRestCall('/tournament/update/regstartdate', 'put', null, {
            tournamentId: tournamentId,
            regStartDate:  value
        })
    }

    return <>
        <li className={markPassed() ? "t-vv-state-past" : "t-vv-state-future"}>
            <div className={markActive() ? "t-vv-state-cell-active" : "t-vv-state-cell"}>{stage?.label}</div>
{/*
            <div className="t-vv-state-cell">{stage && stage?.dateTime && moment(stage.dateTime).format('DD MMM YYYY h:mm a')}</div>
*/}
            <StageDate tournamentId={tournamentId} dateTime={stage?.dateTime} onValueChange={onChange}/>
        </li>
    </>
}

export default StageRegStart;