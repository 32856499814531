import React, {useEffect, useState} from 'react';
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useTournamentContext} from "../TournamentContext";

const GameTable = ({tournamentId, gameId}) => {

    const [tables, setTables] = useState([])
    const [selectedTable, setSelectedTable] = useState()

    const {isEditable} = useTournamentContext();

    useEffect(() => {
        if (tournamentId !== null && tournamentId !== undefined) {
            doRestCallAnonymous("/anonymous/tables/tournament/" + tournamentId, 'get', null, null,
                (response) => setTables(response.body.tables))
        }
    }, [tournamentId])

    useEffect(() => {
        if (gameId !== null && gameId !== undefined) {
            doRestCallAnonymous("/anonymous/table/game/" + gameId, 'get', null, null,
                (response) => setSelectedTable(response.body?.table))
        }
    }, [tables])

    function assign(value) {
        doRestCall("/game/table/assign", "put", null, {
            gameId: gameId,
            tournamentId: tournamentId,
            table: value
        })
    }

    if (isEditable) {
        return <>
            <div className="c-c-hq-games-name">
                <select className="t-vv-select t-vv-select-tables" onChange={(e) => assign(e.target.value) }>
                    <option value="">None</option>
                    {tables?.sort((a, b) => a.table - b.table)
                        .map(table => <option selected={table.table === selectedTable} value={table.table} >{table.table}</option>)
                    }
                </select>
            </div>
        </>
    } else {
        return <>
            <div className="t-vv-games-table-text">
                {selectedTable && selectedTable}
            </div>
        </>
    }
}

export default GameTable;