import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const PlayerWinner = ({winner, gameId, gameMasterId, playerId}) => {

    const [value, setValue] = useState(false);

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id || playerId === currentUser?.id ))
    },[currentUser, gameMasterId])

    useEffect(() => {
        setValue(winner)
    }, [winner]);

    function onWinnerChange(value) {
        doRestCall('/game/update/participant/winner', 'put', null, {
            gameId: gameId,
            participantId: playerId,
            winner: value,
        },
            () => setValue(value))
    }

    return <>
        {!isEditable && <div className="g-v-c-g-r-cell">{value === true ? 'Yes' : ''}</div> }

        {isEditable && value && <i className="fa-solid fa-check"
               onClick={() => onWinnerChange(false)}></i> }
        {isEditable && !value && <i className="fa-regular fa-square"
               onClick={() => onWinnerChange(true)}></i> }
    </>
}

export default PlayerWinner;