import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";

const PlayerCrusadeGameResultsEnemyUnitsDestroyed = ({card, gameId, rosterId, loadGameResults, gameMasterId, playerId}) => {

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id || playerId === currentUser?.id ))
    },[currentUser, gameMasterId])

    function enemyUnitsDestroyed(e, gameResultId, cardId) {
        e.preventDefault()

        doRestCall('/crusade/roster/gameresults/card/enemyunitsdestroyed', 'post', null, {
                gameResultId: gameResultId,
                cardId: cardId,
                gameId: gameId,
                rosterId: rosterId,
                enemyUnitsDestroyed: e.target.value
            },
            (response) => loadGameResults())
    }

    return <>
        {!isEditable && <div className="g-v-info-text">{card.enemyUnitsDestroyed}</div> }
        {isEditable && <input className="g-v-info-input-number"
                type="number" min={0}
               defaultValue={card.enemyUnitsDestroyed}
               onBlur={(e) => enemyUnitsDestroyed(e,  card.gameResultId, card.cardId)}/> }
    </>
}

export default PlayerCrusadeGameResultsEnemyUnitsDestroyed;