import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import PickRandomMissionButton from "./PickRandomMissionButton";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import PickRandomRuleButton from "./PickRandomRuleButton";
import PickRandomDeployButton from "./PickRandomDeployButton";
import PickRandomLayoutButton from "./PickRandomLayoutButton";
import PickLayout from "./PickLayout";

const GameLayout = ({gameId, gameMasterId}) => {


    const {currentUser} = useCurrentUserContext();

    const [layout, setLayout] = useState();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    }, [currentUser, gameMasterId])

    function load() {
        doRestCall("/anonymous/layout/game/" + gameId, "get", null, null,
            (response) => setLayout(response.body));
    }

    useEffect(() => {
        if (gameId !== undefined && gameId !== null) {
            load();
        }
    }, [gameId])

    return <>
        {!layout && isEditable &&
            <div className="row c-c-hq-row">
                <div className="col-lg-8">
                    <div className="g-v-mission">
                        <h6>Layout</h6>
                        <PickLayout gameId={gameId} gameMasterId={gameMasterId} reload={load} selectedLayout={layout}/>
                        <PickRandomLayoutButton gameId={gameId} gameMasterId={gameMasterId} reload={load}/>
                    </div>
                </div>
            </div>
        }
        {layout &&
            <div className="row c-c-hq-row">
                <div className="col-lg-8">
                    <div className="g-v-mission">
                        <h6>Layout</h6>
                        <PickLayout gameId={gameId} gameMasterId={gameMasterId} reload={load} selectedLayout={layout}/>
                        <PickRandomLayoutButton gameId={gameId} gameMasterId={gameMasterId} reload={load}/>
                    </div>
                    <div className="c-c-hq-mission-row">
                        <div>
                            <div className="g-v-mission-name">{layout.name}</div>
                            <img className="g-v-layout" src={layout.filepath} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}

export default GameLayout;