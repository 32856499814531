import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const Weapons = ({rosterId, unitId, weapons, hideWeapons}) => {

    function updateWeaponCount(weaponId, count) {
        doRestCall('/roster/unit/weapon/count', 'put', null, {
            rosterId: rosterId,
            unitId: unitId,
            weaponId: weaponId,
            count: count
        })
    }

    return (
        <>
            {!hideWeapons && <div>
                {weapons.filter(weapon => weapon.type === 'RANGE').length > 0 &&
                    <div>
                        <div className="r-manage-unit-weapon-type">Ranged</div>
                        {weapons.filter(weapon => weapon.type === 'RANGE')
                            .map(weapon => <div className="r-manage-unit-weapon-name">
                                <input className="r-manage-input-number" type="number" placeholder="" min={0}
                                       defaultValue={weapon?.count}
                                       onBlur={(e) => updateWeaponCount(weapon.id, e.target.value)}/> {weapon.name}
                                {/*{weapon?.profiles?.map(profile => <div>{profile.name}</div>)}*/}
                            </div>)}
                    </div>}
                {weapons.filter(weapon => weapon.type === 'MELEE').length > 0 &&
                    <div>
                        <div className="r-manage-unit-weapon-type">Melee</div>
                        {weapons.filter(weapon => weapon.type === 'MELEE')
                            .map(weapon => <div className="r-manage-unit-weapon-name">
                                <input className="r-manage-input-number" type="number" placeholder="" min={0}
                                       defaultValue={weapon?.count}
                                       onBlur={(e) => updateWeaponCount(weapon.id, e.target.value)}/> {weapon.name}
                                {/* {weapon?.profiles?.map(profile => <div>{profile.name}</div>)}*/}
                            </div>)}
                    </div>}
            </div>}
        </>
    );
};

export default Weapons;

