import React from 'react';
import {List, Space, Typography} from 'antd';
const { Text, Link } = Typography;

const Errors = ({errors}) => {

    return <>
        <div>
            {errors?.map(error => <p className="u-r-error" >{error.error}</p>)}
        </div>
    </>
}

export default Errors;