import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Space, Table} from "antd";
import moment from "moment/moment";

import {Analytics} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";
import Rounds from "../view/components/Rounds";

const TournamentBoard = () => {

    Analytics("/tournament/board", "Tournament Board")

    let {tournamentId} = useParams();
    const navigate = useNavigate();


    const [loading, setLoading] = useState(true)
    const [tournament, setTournament] = useState({});

    const [players, setPlayers] = useState([]);

    const [random, setRandom] = useState(0);

    const [reload, setReload] = useState(false);


    useEffect(() => {
        load()
        setRandom(Math.random())
    }, [])

    useEffect(() => {
        if (reload) {
            setReload(false)
            load()
        }
    }, [reload])


    useEffect(() => {
        loadBoard()
    }, [random])

    function onBoardSuccess(response) {

    }

    const loadBoard = () => {
        doRestCall( '/tournament/liveboard/' + tournamentId, 'get', null, null,
            (response) => {
                setReload(response)
            }, null,
            () => {
                setRandom(Math.random())
            })
    }


    function onViewSuccess(response) {
        setTournament(response.body)

        //

        let tournamentPlayers = response.body.players;

        if (tournamentPlayers === undefined || tournamentPlayers === null || tournamentPlayers.length === 0) {
        } else {

            for (let i = 0; i < tournamentPlayers.length; i++) {
                tournamentPlayers[i] = {
                    id: tournamentPlayers[i].user.id,
                    name: tournamentPlayers[i].user.username,
                    value: tournamentPlayers[i].user.id,
                    label: tournamentPlayers[i].user.username,
                    key: tournamentPlayers[i].user.key,
                    score: tournamentPlayers[i].score,
                    wins: tournamentPlayers[i].stats.wins,
                    draws: tournamentPlayers[i].stats.draws,
                    losses: tournamentPlayers[i].stats.losses
                }
            }

            setPlayers(tournamentPlayers.sort((p1, p2) => p2.score - p1.score))
        }
        //

        setLoading(false)

    }

    const load = () => {
        doRestCall( '/tournament/view/' + tournamentId, 'get', null, null, onViewSuccess)
    }

    const usersTableColumns = [{
        title: 'Name', dataIndex: 'label', key: 'key', width: '40%',
    },{
        title: 'Faction', dataIndex: 'faction', key: 'faction', width: '30%',
    },{
        title: 'Score', dataIndex: 'score', key: 'key', width: '15%',
    },{
        title: 'Wins', dataIndex: 'wins', key: 'key', width: '5%',
    },{
        title: 'Draws', dataIndex: 'draws', key: 'key', width: '5%',
    },{
        title: 'Losses', dataIndex: 'losses', key: 'key', width: '5%',
    }];

    return (
        <>
            <Auth/>
            <h2 className="tournBoardTitle">Tournament Board <span data-cy="name">{tournament.name}</span></h2>
            {loading && <p>loading</p>}
            {!loading &&
                <Space direction="vertical" className="tournView">

                    {(loading || players.length === 0) &&
                        <>
                            <h2 className="tournBoardTableTitles">Participants:</h2>
                            <div className="tournViewNoTable"> No Participants Found</div>
                        </>
                    }
                    {(!loading && players.length > 0) && <Form.Item name="usersFormItem">
                        <h2 className="tournBoardTableTitles">Participants:</h2>
                        <div className="tournViewTable">
                            <Table
                                columns={usersTableColumns}
                                dataSource={players}
                                pagination={
                                    {
                                        pageSize: 10
                                    }
                                }
                                id={"usersTable"}
                                style={{width: '100%', minWidth: '42rem'}}
                                showHeader={true}
                            />
                        </div>
                    </Form.Item>
                    }

                    <Rounds tournamentId={tournament.id} rounds={tournament.rounds} />

                </Space>
            }
        </>
    );
};

export default TournamentBoard;

