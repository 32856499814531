import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const Models = ({rosterId, unitId, isSingleModelUnit, models}) => {

    return (
        <>
            {!isSingleModelUnit && models?.map(model =>
                <div className="r-view-unit-model-name">{model?.count} x {model?.name}</div>)
            }
        </>
    );
};

export default Models;

