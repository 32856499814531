import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popconfirm, Popover, Space, Table, Typography} from "antd";
import "./styles-t-view.css"
import {Analytics} from "../../GoogleAnalytics";
import TournamentStarted from "./TournamentStarted";
import TournamentRegistrationOpen from "./TournamentRegistrationOpen";
import TournamentRegistrationClosed from "./TournamentRegistrationClosed";
import TournamentEnded from "./TournamentEnded";
import TournamentCreated from "./TournamentCreated";
import Status from "./components/Status";
import Details from "./components/Details";
import {doRestCall, doRestCallAnonymous} from "../../AppUtils";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";
import Spinner from "../../_components/Spinner";
import TournamentRegistrationOpenAnonymous from "./TournamentRegistrationOpenAnonymous";
import TournamentRegistrationClosedAnonymous from "./TournamentRegistrationClosedAnonymous";
import TournamentStartedAnonymous from "./TournamentStartedAnoymous";
import TournamentCreatedAnonymous from "./TournamentCreatedAnonymous";
import TournamentEndedAnonymous from "./TournamentEndedAnonymous";

const TournamentViewAnonymous = () => {

    Analytics("/tournament/view", "Tournament View (anon)")

    let {tournamentId} = useParams();
    const navigate = useNavigate();

    const {currentUser} = useCurrentUserContext();
    const [loading, setLoading] = useState(true)
    const [tournament, setTournament] = useState({});
    const {Title} = Typography;

    useEffect(() => {
        load()
    }, [])


    function onViewSuccess(response) {
        setTournament(response.body)
        setLoading(false)
    }

    const load = () => {
        setLoading(true)
        doRestCallAnonymous( '/anonymous/tournament/' + tournamentId + '/view', 'get', null, null,
            onViewSuccess,
            null,
            () => setLoading(false))
    }

    function navigateBack() {
        navigate("/tournaments")
    }

    return (
        <>
            <Spinner isLoading={loading} >
                <div className="t-v-mainheading-section">
                    {(tournament && tournament.status === 'REGISTRATION_OPENED') &&
                        <TournamentRegistrationOpenAnonymous tournament={tournament}/>}
                    {(tournament && tournament.status === 'REGISTRATION_CLOSED') &&
                        <TournamentRegistrationClosedAnonymous tournament={tournament}/>}
                    {(tournament && tournament.status === 'STARTED') && <TournamentStartedAnonymous tournament={tournament}/>}
                    {(tournament && tournament.status === 'CREATED') && <TournamentCreatedAnonymous tournament={tournament}/>}
                    {(tournament && tournament.status === 'ENDED') && <TournamentEndedAnonymous tournament={tournament}/>}

                    <div className="row mb-3 justify-content-between t-v-buttons-container">
                        <div className="col-auto">
                            <button className="t-v-tlist-btn" onClick={navigateBack}>Back</button>
                        </div>
                    </div>
                </div>
            </Spinner>

        </>
    );
};

export default TournamentViewAnonymous;

