import React, {useEffect, useState} from 'react';
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {useTournamentContext} from "../TournamentContext";

const GamePlayers = ({gameId, tournamentId}) => {
    const navigate = useNavigate();

    const {isEditable} = useTournamentContext();

    const [players, setPlayers] = useState([])
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    useEffect(() => {
        if (gameId !== undefined && gameId !== null) {
            doRestCallAnonymous("/anonymous/game/" + gameId + "/players", "get", null, null,
                (response) => {
                    setPlayers(response.body);
                })
        }
    }, [gameId])

    useEffect(() => {
        if (tournamentId !== null && tournamentId !== undefined) {
            doRestCall("/tournament/users", 'get', null, null,
                (response) => {
                    setUsers(response.body.users);
                })
        }
    }, [tournamentId])

    function navigateToGame() {
        window.open('/game/view/' + gameId,'_blank', );
    }

    if (isEditable) {
        return <>
            <div className="t-vv-games-player-name-row">
                <div className="t-vv-player">
                    <select className="t-vv-select " onChange={(e) => setSelectedUser(e.target.value)} >
                        <option value="">None</option>
                        {users?.sort((a, b) => a.username.localeCompare(b.username))
                            .map(user => <option selected={players[0] && players[0].userId === user.userId} value={user.userId} >{user.username}</option>)
                        }
                    </select>
                    <select className="t-vv-select " onChange={(e) => setSelectedUser(e.target.value)} >
                        <option value="">None</option>
                        {users?.sort((a, b) => a.username.localeCompare(b.username))
                            .map(user => <option selected={players[1] && players[1].userId === user.userId} value={user.userId} >{user.username}</option>)
                        }
                    </select>
                </div>
            </div>
        </>
    } else {
        return <>
            <span className={players[0] && players[0].winner? "t-vv-games-player-name-winner" : "t-vv-games-player-name"}>
                {players[0] && players[0].username}
            </span>
            <span className="t-vv-games-player-vs">
                {players[0] && players[1] && "vs" }
            </span>
            <span className={players[1] && players[1].winner? "t-vv-games-player-name-winner" : "t-vv-games-player-name"}>
                {players[1] && players[1].username}
            </span>
            <span className="t-vv-games-link">
                <i className="fa-solid fa-arrow-up-right-from-square fa-xs" onClick={(e) => navigateToGame()}></i>
            </span>
        </>
    }

}

export default GamePlayers;