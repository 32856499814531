import React, {useEffect, useState} from "react";
import AllianceDiscord from "./AllianceDiscord";


const AllianceName = ({value, discordChannel, allianceId, reloadAlliances}) => {

    const [hidden, setHidden] = useState(false);

    return (
        <>
            <div className="c-c-hq-alliance-name" hidden={hidden}
                 onClick={() => setHidden(true)}>
                {value}
            </div>
            <AllianceDiscord allianceId={allianceId} discordChannel={discordChannel} reloadAlliances={reloadAlliances} />
            <div hidden={!hidden}>
                <input className="c-c-hq-alliance-name-input" defaultValue={value} onBlur={() => setHidden(false)} />
            </div>
        </>
    );
};

export default AllianceName;

