import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Analytics} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";

import "./styles-g-view.css"
import GameSettings from "./components/v2/GameSettings";
import GameName from "./components/v2/GameName";
import GameDate from "./components/v2/GameDate";
import GameMaster from "./components/v2/GameMaster";
import GameUniverse from "./components/v2/GameUniverse";
import GameNotes from "./components/v2/GameNotes";
import GameWinner from "./components/v2/GameWinner";
import DeleteGameButton from "./components/v2/DeleteGameButton";
import Players from "./components/v2/Players";
import GameMission from "./components/v2/GameMission";
import GameRule from "./components/v2/GameRule";
import GameDeploy from "./components/v2/GameDeploy";
import GameLayout from "./components/v2/GameLayout";

const GameView = () => {

    Analytics("/game/view", "Game View")

    const navigate = useNavigate();

    let {gameId} = useParams();

    const [loading, setLoading] = useState(true)
    const [game, setGame] = useState({});
    const [universe, setUniverse] = useState();

    useEffect(() => {
        load()
    }, [])

    function onGameViewSuccess(response) {
        setGame(response.body)
        setLoading(false)
    }

    const load = () => {
        doRestCall('/game/view/' + gameId, 'get', null, null, onGameViewSuccess)
    }

    return (
        <>
            <Auth/>

            <div className="container c-c-hq-container">
                <div className="c-c-hq-name"><h2 className="">Game Details</h2></div>

                <div className="m-5">
                    <div className="row c-c-hq-row ">
                        <div className="col-sm-4 col-5">
                            <h6>Info</h6>
                            <div className="g-v-info-text g-v-info-row"><div className="g-v-info-cell-header">Name</div>
                                <GameName gameMasterId={game.gameMasterId}  name={game.name} gameId={game.id} />
                            </div>
                            <div className="g-v-info-text g-v-info-row"><div className="g-v-info-cell-header">Date</div>
                                <GameDate gameMasterId={game.gameMasterId}  dateTime={game.dateTime} gameId={game.id} />
                            </div>
                            <div className="g-v-info-text g-v-info-row"><div className="g-v-info-cell-header">Master</div>
                                <GameMaster gameMasterId={game.gameMasterId} gameMasterUsername={game.gameMasterUsername} gameId={game.id} />
                            </div>
                            <div className="g-v-info-text g-v-info-row"><div className="g-v-info-cell-header">Universe</div>
                                <GameUniverse gameMasterId={game.gameMasterId} universe={game.universe} gameId={game.id} setUniverse={setUniverse} />
                            </div>
                            <div className="g-v-info-text g-v-info-row"><div className="g-v-info-cell-header">Winner</div>
                                <GameWinner name={game.gameWinnerUsername}/>
                            </div>
                            <div className="g-v-info-text g-v-info-row"><div className="g-v-info-cell-header">Notes</div>
                                <GameNotes notes={game.notes} gameMasterId={game.gameMasterId}  gameId={game.id} />
                            </div>
                        </div>
                        <GameSettings gameMasterId={game.gameMasterId}  gameId={game.id} onSettingsChanged={load} />
                    </div>
                    <div className="row c-c-hq-row" >
                        <DeleteGameButton gameMasterId={game.gameMasterId} gameId={game.id} />
                    </div>
                        <GameMission gameMasterId={game.gameMasterId} gameId={game.id} />
                        <GameRule gameMasterId={game.gameMasterId} gameId={game.id} />
                        <GameDeploy gameMasterId={game.gameMasterId} gameId={game.id} />
                        <GameLayout gameMasterId={game.gameMasterId} gameId={game.id} />
                    <div className="row c-c-hq-row" >
                        <Players gameId={game.id}
                                 gameMasterId={game.gameMasterId}
                                 gameSettings={game.gameSettings}
                                 universe={universe} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default GameView;

