import React, {useEffect, useState} from "react";
import {
    Button,
    Form, Input, Modal, Space, Table,
} from "antd";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../../user/auth/Auth";
import {Analytics} from "../../../GoogleAnalytics";
import {doRestCall} from "../../../AppUtils";
import {createForce} from "../ForceConnector";
import {notificationCreateFailure, notificationCreateForceSuccess} from "../ForceNotifications";
import Spinner from "../../../_components/Spinner";
import moment from "moment/moment";

import "./c-f-all-styles.css"

const Forces = () => {

    Analytics("/crusade/forces", "Crusade Forces")

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [forces, setForces] = useState([]);

    const [all, setAll] = useState(true);
    const [created, setCreated] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [newForceError, setNewForceError] = useState()
    const [newForceName, setNewForceName] = useState({})

    function onForcesSuccess(response) {
        setForces(response.body)
    }

    useEffect(() => {
        doRestCall( '/crusade/forces', 'get', {
            created: created,
            all: all
        }, null, onForcesSuccess, null, () => { setLoading(false); } )
    }, [all, created])


    function view(id) {
        navigate('/crusade/force/' + id + '/view')
    }

    function onCreated() {
        setCreated(true)
        setAll(false)
    }

    function onAll() {
        setCreated(false)
        setAll(true)
    }

    function onOk() {
        if (newForceName === null || newForceName === undefined || newForceName === "") {
            setNewForceName("Name is required")
            return
        }

        doRestCall('/crusade/force/create', 'post', null, {
            name: newForceName
        }, (response) => {
            setNewForceName("")
            setNewForceError("")
            setModalOpen(false)
            navigate('/crusade/force/' + response.body.id + '/manage')
        })
    }

    function onCancel() {
        setNewForceName("")
        setNewForceError("")
        setModalOpen(false)
    }

    return (
        <>
            <Auth/>

            <Spinner isLoading={loading}>
                <div className="row d-flex justify-content-center mt-5">
                    <div className="col-md-11">
                        <div className="row mb-3 justify-content-between">
                            <div className="col-auto">
                                <button className={all ? "c-f-all-tlist-btn-active" : "c-f-all-tlist-btn" } onClick={(e) => onAll(e)}>ALL</button>
                                <button className={created ? "c-f-all-tlist-btn-active" : "c-f-all-tlist-btn" } onClick={(e) => onCreated(e)}>Created By Me</button>
                            </div>
                            <div className="col-auto">
                                <button className="c-f-all-tlist-btn" onClick={() => {setModalOpen(true)}}>Create Force</button>
                            </div>
                        </div>

                        <div className="c-f-all-vtable-container">
                            <table className="c-f-all-vtable">
                                <thead className="c-f-all-vtable-head">
                                <tr className="c-f-all-vtable-row">
                                    <th className="c-f-all-vtable-header">Name</th>
                                    <th className="c-f-all-vtable-header">Supply Used</th>
                                </tr>
                                </thead>
                                <tbody className="c-f-all-vtable-body">
                                {forces?.map(force =>
                                    <tr className="c-f-all-vtable-row">
                                        <td className="c-f-all-vtable-cell c-f-all-vtable-cell-name">
                                            <a onClick={() => view(force.id)}>{force.name}</a>
                                        </td>
                                        <td className="c-f-all-vtable-cell">{force.supplyUsed}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Spinner>

            <Modal title="Create Force" open={modalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
                {newForceError && <p>{newForceError}</p>}
                <Space direction="horizontal">
                    <label>Name</label>
                    <input type="text" placeholder="Name" onChange={(e) => setNewForceName(e.target.value)}/>
                </Space>
            </Modal>
        </>
    );
};

export default Forces;

