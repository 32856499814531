import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import PlayerCrusadeGameResultsSurvived from "./PlayerCrusadeGameResultsSurvived";
import PlayerCrusadeGameResultsMarkOfGreatness from "./PlayerCrusadeGameResultsMarkOfGreatness";
import PlayerCrusadeGameResultsEnemyUnitsDestroyed from "./PlayerCrusadeGameResultsEnemyUnitsDestroyed";

const PlayerCrusadeGameResults = ({playerId, gameId, gameMasterId}) => {

    const [selectedRosterId, setSelectedRosterId] = useState(undefined)
    const [gameResults, setGameResults] = useState({})

    useEffect(() => {
        if (gameId !== undefined && gameId !== null && playerId !== undefined && playerId !== null) {
            loadRoster(playerId, gameId)
        }
    }, [playerId, gameId])

    useEffect(() => {
        if (gameId !== undefined && gameId !== null && selectedRosterId !== undefined && selectedRosterId !== null) {
            loadGameResults();
        }
    }, [gameId, selectedRosterId])

    function loadGameResults() {
        doRestCall('/crusade/roster/' + selectedRosterId + '/game/' + gameId + '/gameresults', 'get', null, null,
            (response) => {
                setGameResults(response.body)
            })
    }

    function loadRoster(playerId, gameId) {
        doRestCall('/game/' + gameId + '/user/' + playerId + '/roster', 'get', null, null,
            (response) => {
                setSelectedRosterId(response.body?.rosterId)
            })
    }

    return <>
        {gameResults?.cards && <div className="g-v-text g-v-c-g-r-row "><div className="g-v-c-g-r-cell g-v-c-g-r-main">Crusade Game Results</div></div> }
        <div className="col-lg-8 g-v-c-g-r-table">
            {gameResults?.cards &&<div className="g-v-c-g-r ">
                <div className="g-v-c-g-r-header">Name</div>
                <div className="g-v-c-g-r-header">Survived</div>
                <div className="g-v-c-g-r-header">Enemy Units Destroyed</div>
                <div className="g-v-c-g-r-header">Mark of Greatness</div>
            </div>}
            {gameResults?.cards?.map(card => <div className="g-v-c-g-r ">
                    <div className="g-v-c-g-r-cell-name ">{card.cardName}</div>
                    <div className="g-v-c-g-r-cell-survived ">
                        <PlayerCrusadeGameResultsSurvived card={card} rosterId={selectedRosterId}
                                                          gameId={gameId} loadGameResults={loadGameResults}
                                                          gameMasterId={gameMasterId} playerId={playerId} />
                    </div>
                    <div className="g-v-c-g-r-cell-enemy-destroyed ">
                        <PlayerCrusadeGameResultsEnemyUnitsDestroyed card={card} rosterId={selectedRosterId}
                                                                     gameId={gameId} loadGameResults={loadGameResults}
                                                                     gameMasterId={gameMasterId} playerId={playerId} />
                    </div>
                    <div className="g-v-c-g-r-cell-mark-greatness ">
                        <PlayerCrusadeGameResultsMarkOfGreatness card={card} rosterId={selectedRosterId}
                                                                 gameId={gameId} loadGameResults={loadGameResults}
                                                                 gameMasterId={gameMasterId} playerId={playerId} />
                    </div>
                </div>
            )}

        </div>
    </>
}

export default PlayerCrusadeGameResults;