import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const PlayerScore = ({score, gameId, gameMasterId, playerId}) => {

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id || playerId === currentUser?.id ))
    },[currentUser, gameMasterId])

    function onChange(value) {
        doRestCall('/game/update/participant/score', 'put', null, {
            gameId: gameId,
            participantId: playerId,
            score: value
        })
    }

    return <>
        {!isEditable && <div className="g-v-c-g-r-cell">{score}</div> }

        {isEditable && <input className="g-v-info-input-number" type="number" min={0} defaultValue={score}
               onChange={(e) => onChange(e.target.value)} /> }
    </>
}

export default PlayerScore;