import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import {Select} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";

const GameMaster = ({gameMasterId, gameMasterUsername, gameId}) => {


    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    useEffect(() => {
        setLoading(true)
        doRestCall('/users/all', 'get', null, null,
            (response) => {
                for (let i = 0; i < response.body.length; i++) {
                    response.body[i] = {
                        id: response.body[i].id,
                        name: response.body[i].username,
                        value: response.body[i].id,
                        label: response.body[i].username,
                    }
                }
                setUsers(response.body)
            }, null, () => setLoading(false));
    }, [])

    const onChange = (value) => {
        doRestCall('/game/update/gamemaster', 'put', null, {
            gameId: gameId,
            gameMasterId: value,
        })
    };

    return <>
        {!isEditable && <div className="g-v-info-cell">{gameMasterUsername}</div> }

        {isEditable && <select className="g-v-info-gamemaster-select" onChange={(e) => onChange(e.target.value)}>
            <option value="">None</option>
            {users?.map(user =>
                <option selected={gameMasterId && gameMasterId === user.id} value={user.id} >{user.name}</option>)
            }
        </select> }
    </>
}

export default GameMaster;