import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import RegistrationAlreadyRegistered from "./RegistrationAlreadyRegistered";
import {RegistrationStatus, TournamentStatus} from "../../config";
import RegistrationOpen from "./RegistrationOpen";

const Registration = ({tournamentId, onRegistrationUpdate}) => {

    const [isAlreadyRegistered, setAlreadyRegistered] = useState(false)
    const [status, setStatus] = useState()

    useEffect(() => {
        if (tournamentId !== undefined && tournamentId !== null) {
            doRestCallAnonymous('/anonymous/tournament/' + tournamentId + "/status", 'get', null, null,
                (response) => {
                    setStatus(response.body.status)
                })
        }
    }, [tournamentId]);

    function registrationUpdated() {
        load();
        onRegistrationUpdate();
    }

    function load() {
        doRestCall('/tournament/' + tournamentId + '/isregistered', 'get', null, null,
            (response) => setAlreadyRegistered(response.body.status === RegistrationStatus.REGISTERED))
    }

    useEffect(() => {
        if (tournamentId !== null && tournamentId !== undefined) {
            load();
        }
    }, [tournamentId])

    if (status === TournamentStatus.REGISTRATION_OPENED) {
        return <>
            <div>Registration</div>

            {isAlreadyRegistered && <RegistrationAlreadyRegistered tournamentId={tournamentId}
                                                                   onRegistrationUpdate={registrationUpdated}   /> }

            {!isAlreadyRegistered && <RegistrationOpen tournamentId={tournamentId}
                                                       onRegistrationUpdate={registrationUpdated}  /> }
        </>
    } else {
        return <></>
    }

}

export default Registration;