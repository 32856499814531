import {build, env, isDev} from "./AppUtils";
import { datadogRum } from '@datadog/browser-rum';


export function DataDogView(view) {
    datadogRum.setViewName(view)
}

export function DataDogUserInfo(currentUser) {
    if (currentUser) {
        window.DD_RUM.setUserProperty('id', currentUser.id);
        window.DD_RUM.setUserProperty('name', currentUser.username);
    } else {
        window.DD_RUM.clearUser();
    }
}

export function DataDogInit() {
    if (!isDev()) {
        datadogRum.init({
            applicationId: 'eef8564b-59b3-42de-8694-eb5d8d442bc1',
            clientToken: 'pubf9f669bc70499d922c750047e4c9de6c',
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'us5.datadoghq.com',
            service: 'smartroster',
            env: env(),
            // Specify a version number to identify the deployed version of your application in Datadog
            version: build(),
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            defaultPrivacyLevel: 'allow',
            silentMultipleInit: true,
            trackUserInteractions: true,
        });
        window.DD_RUM.startSessionReplayRecording();
    }
}
