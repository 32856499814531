import React from "react";
import {useNavigate} from "react-router-dom";
import {Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const DeleteGameButton = ({gameId, gameMasterId}) => {

    const navigate = useNavigate();


    const {currentUser} = useCurrentUserContext();

    function onConfirmDelete() {
        doRestCall('/game/delete/' + gameId, 'delete', null, null, onSuccess)
    }

    function onSuccess() {
        navigate('/games')
    }

    return (
        <>
            {(currentUser?.role === 'ADMIN' || currentUser?.id === gameMasterId) &&
                <span className="g-v-popconfirm">
                    <Popconfirm
                        title={"Delete game"}
                        description={"Are you sure you want to delete this game?"}
                        onConfirm={onConfirmDelete}>
                        <button className="g-v-info-delete-button">Delete Game</button>
                    </Popconfirm>
                </span>
            }
        </>
    );
};

export default DeleteGameButton;