import React, {useEffect, useState} from "react";
import {
    Button, Form, Input,
    Space,
    Table
} from "antd";
import moment from "moment";
import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Analytics} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";
import MarkdownEditor from "@uiw/react-markdown-editor";
import news from "../all/News";

const NewsEdit = () => {

    Analytics("/news/edit", "News Edit")

    let {newsId} = useParams();

    const navigate = useNavigate();

    const [form] = Form.useForm();

    useEffect(() => {
        doRestCall("/news/view/" + newsId, "get", null, null,
            (news) => {
                form.setFieldsValue({
                    publishingDate: news.body.publishingDate,
                    title: news.body.title,
                    id: news.body.id,
                    body: news.body.body,
                })
            })
    }, [])

    function onFinish(news) {
        doRestCall( '/news/update', 'put', null, news)

        navigate("/news")

    }

    return (
        <>
            <Auth />


            <p>News Edit</p>


            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  layout="horizontal"
            >

                <Form.Item
                    name="id"
                    label="id"
                    hidden={true}
                >
                    <Input   />
                </Form.Item>

                <Form.Item
                    name="publishingDate"
                    label="publishingDate"
                    hidden={true}
                >
                    <Input   />
                </Form.Item>

                <Form.Item
                    name="title"
                    label="Title"
                >
                    <Input   />
                </Form.Item>

                <Form.Item
                    name="body"
                    label="Body"
                >
                    <MarkdownEditor   />
                </Form.Item>

                <Button type="primary" htmlType="submit" data-cy="submit">
                    Submit
                </Button>

            </Form>

        </>
    );
};

export default NewsEdit;

