import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";

const GameUniverse = ({universe, gameId, gameMasterId, setUniverse}) => {

    const [universes, setUniverses] = useState([]);
    const [loading, setLoading] = useState(false);

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setUniverse(universe)
    }, [universe])

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    useEffect(() => {
        setLoading(true)
        doRestCall( '/codex/universes', 'get', null, null,
            (response) => setUniverses(response.body), null, () => setLoading(false))
    }, []);

    const onChange = (value) => {
        doRestCall('/game/update/universe', 'put', null, {
            gameId: gameId,
            universe: value,
        },
            () => setUniverse(value))
    };

    return <>
        {!isEditable && <div className="g-v-info-cell">{universe}</div> }

        {isEditable && <select className="g-v-info-universes-select" onChange={(e) => onChange(e.target.value)} >
            <option value="">None</option>
            {universes?.map(u =>
                <option defaultValue={universe} selected={universe && universe === u} value={u} >{u}</option>)
            }
        </select>}

    </>
}

export default GameUniverse;