import {createContext, useContext, useEffect, useMemo, useState} from "react";


const TournamentContext = createContext({
    tournamentId: null,
    setTournamentId: () => {},
    isEditable: null,
});

export const useTournamentContext = () => useContext(TournamentContext)

export const TournamentContextProvider = ({children, currentUser}) => {
    const [tournamentId, setTournamentId] = useState();
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role))
    },[currentUser])

    const result = useMemo(() => {
        return {
            tournamentId, setTournamentId, isEditable,
        }
    }, [ tournamentId, setTournamentId, isEditable])

    return <TournamentContext.Provider value={result}>{children}</TournamentContext.Provider>
}

