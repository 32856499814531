import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import Factions from "./Factions";
import {useTournamentContext} from "../TournamentContext";

const Master = ({tournamentId, masterId}) => {

    const {isEditable} = useTournamentContext();

    const [users, setUsers] = useState([]);

    useEffect(() => {

        if (tournamentId !== null && tournamentId !== undefined) {
            doRestCall("/tournament/users", 'get', null, null,
                (response) => {
                    setUsers(response.body.users);
                })
        }

    }, [tournamentId])

    function onMasterUpdate(userId) {
        doRestCall("/tournament/update/master", 'put', null, {
            tournamentId: tournamentId,
            userId: userId,
        })
    }

    if (isEditable) {
        return <>
                <select className="t-vv-select" onChange={(e) => onMasterUpdate(e.target.value)} >
                    <option value="">None</option>
                    {users?.sort((a, b) => a.username.localeCompare(b.username))
                        .map(user => <option selected={masterId === user.userId} value={user.userId} >{user.username}</option>)
                    }
                </select>
        </>
    }

    if (!isEditable) {
        return <>
            {users.filter(user => user.userId === masterId)
                .map(user => <div className="t-vv-text">{user.username}</div>)}
        </>
    }


}

export default Master;