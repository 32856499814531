import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCallAnonymous} from "../../../AppUtils";
import Deploy from "./Deploy";

const Deploys = ({}) => {

    const [deploys, setDeploys] = useState([]);

    useEffect(() => {
        doRestCallAnonymous("/anonymous/deploys", 'get', null, null,
            (response) => setDeploys(response.body.deploys));
    }, [])


    return <>
        <div className="col-sm-3 col-5">
            <h6>Deploys</h6>

            {deploys?.map((deploy) => <div key={deploy.id} className="t-vv-text t-vv-row">
                    <div className="t-vv-cell"><Deploy name={deploy.name} filepath={deploy.filepath} /></div>
                </div>
            )}

        </div>
    </>
}

export default Deploys;