import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import RosterLock from "./RosterLock";

const PlayerCrusadeRoster = ({playerId, gameId, gameMasterId}) => {

    const [rosters, setRosters] = useState([]);
    const [selectedRosterId, setSelectedRosterId] = useState(undefined)
    const [selectedRosterName, setSelectedRosterName] = useState("")
    const [locked, setLocked] = useState(false)

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id || playerId === currentUser?.id ))
    },[currentUser, gameMasterId])


    useEffect(() => {
        if (gameId !== undefined && gameId !== null && playerId !== undefined && playerId !== null) {
            load()
        }
    },[playerId, gameId])

    function load() {
        doRestCall('/game/' + gameId + '/user/' + playerId + '/roster', 'get', null, null,
            (response) => {
                setSelectedRosterId(response.body?.rosterId)
                setLocked(response.body?.locked)
                setSelectedRosterName(response.body?.name)
            })
    }

    useEffect(() => {
        loadRosters()
    }, [playerId, gameId])

    function loadRosters() {
        doRestCall('/game/usercrusaderosters', 'get', { userId: playerId }, null,
            (response) => setRosters(response.body))
    }

    function onChange(e) {
        e.preventDefault()

        console.log(e.target.value)

        if ("" === e.target.value) {
            doRestCall('/game/roster/deassign', 'put', null, {
                gameId: gameId,
                userId: playerId
            })
            setSelectedRosterId(undefined)
        } else {
            doRestCall('/game/roster/assign', 'put', null, {
                gameId: gameId,
                rosterId: e.target.value,
                userId: playerId
            })
            setSelectedRosterId(e.target.value)
        }

    }

    return <>
        {!isEditable && <div className="g-v-c-g-r-cell">{selectedRosterName}</div> }

        {isEditable && locked && <div className="g-v-c-g-r-cell">{selectedRosterName} <RosterLock playerId={playerId} gameId={gameId} gameMasterId={gameMasterId}
                                                                                                  selectedRosterId={selectedRosterId} locked={locked} reload={load} /></div> }

        {isEditable && !locked && <div>
            <select className="g-v-info-players-select" onChange={(e) => onChange(e)} >
            <option selected={selectedRosterId === null || selectedRosterId === undefined} value="">None</option>
            {rosters?.sort()
                .map(roster =>
                    <option selected={selectedRosterId === roster.rosterId} value={roster.rosterId}>{roster.name}</option>)
            }
            </select>
            <RosterLock playerId={playerId} gameId={gameId} gameMasterId={gameMasterId}
                        selectedRosterId={selectedRosterId} locked={locked} reload={load} />
        </div>}

    </>
}

export default PlayerCrusadeRoster;