import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";

const PlayerCrusadeGameResultsMarkOfGreatness = ({card, gameId, rosterId, loadGameResults, gameMasterId, playerId}) => {

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id || playerId === currentUser?.id ))
    },[currentUser, gameMasterId])

    function markOfGreatness(e, markOfGreatness, gameResultId, cardId) {
        e.preventDefault()

        doRestCall('/crusade/roster/gameresults/card/markofgreatness', 'post', null, {
                gameResultId: gameResultId,
                cardId: cardId,
                gameId: gameId,
                rosterId: rosterId,
                markOfGreatness: markOfGreatness
            },
            (response) => loadGameResults())
    }

    return <>
        {!isEditable && card.markOfGreatness && <i className="fa fa-check"></i> }

        {isEditable && card.markOfGreatness &&
            <i className="fa fa-check"
                onClick={(e) => markOfGreatness(e, false, card.gameResultId, card.cardId)}></i>}

        {isEditable && !card.markOfGreatness &&
            <i className="fa-regular fa-square"
               onClick={(e) => markOfGreatness(e, true, card.gameResultId, card.cardId)}></i>}
    </>
}

export default PlayerCrusadeGameResultsMarkOfGreatness;