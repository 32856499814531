import React, {useEffect, useState} from "react";


const StateName = ({value}) => {

    const [hidden, setHidden] = useState(false);

    return (
        <>
            <div className="c-c-hq-state-name" hidden={hidden}
                onClick={() => setHidden(true)}>
                {value}
            </div>
            <div  hidden={!hidden}>
                <input className="c-c-hq-state-name-input" defaultValue={value} onBlur={() => setHidden(false)} />
            </div>
        </>
    );
};

export default StateName;

