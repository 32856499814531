import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall} from "../../../AppUtils";
import {useTournamentContext} from "../TournamentContext";

const Universe = ({universe, tournamentId, masterId}) => {

    const [universes, setUniverses] = useState([]);
    const [loading, setLoading] = useState(false);

    const {isEditable} = useTournamentContext();
    
    useEffect(() => {
        setLoading(true)
        doRestCall( '/codex/universes', 'get', null, null,
            (response) => setUniverses(response.body), null, () => setLoading(false))
    }, []);

    const onChange = (value) => {
        doRestCall('/tournament/update/universe', 'put', null, {
                tournamentId: tournamentId,
            universe: value,
        })
    };

    return <>
        {!isEditable && <div className="g-v-info-cell">{universe}</div> }

        {isEditable && <select className="g-v-info-universes-select" onChange={(e) => onChange(e.target.value)} >
            <option value="">None</option>
            {universes?.map(u =>
                <option defaultValue={universe} selected={universe && universe === u} value={u} >{u}</option>)
            }
        </select>}

    </>
}

export default Universe;