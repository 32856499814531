import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import Registration from "./Registration";
import NewPlayer from "./NewPlayer";
import {TournamentStage} from "../../config";
import {useTournamentContext} from "../TournamentContext";

const Players = ({tournamentId, tournamentStatus}) => {

    const {isEditable} = useTournamentContext();
    const [players, setPlayers] = useState([])

    const [currentStage, setCurrentStage] = useState()

    useEffect(() => {
        if (tournamentId !== undefined && tournamentId !== null) {
            doRestCallAnonymous('/anonymous/tournament/' + tournamentId + "/status", 'get', null, null,
                (response) => {
                    setCurrentStage(response.body.status)
                })
        }
    }, [tournamentId]);

    function load() {
        doRestCallAnonymous('/anonymous/tournament/' + tournamentId + "/players", 'get', null, null,
            (response) => {
                setPlayers(response.body.players);
            })
    }

    useEffect(() => {

        if (tournamentId !== null && tournamentId !== undefined) {
            load();
        }

    }, [tournamentId])

    function removePlayer(playerId) {
        doRestCall("/tournament/update/participant", 'put', null, {
                tournamentId: tournamentId,
                participantId: playerId,
                state: false,
            },
            (response) => {load()})
    }

    if (currentStage && [TournamentStage.CREATED, TournamentStage.REGISTRATION_OPENED, TournamentStage.REGISTRATION_CLOSED].includes(currentStage)) {
        return <>
            <div className="col-lg-8">
                {currentStage === TournamentStage.REGISTRATION_OPENED && <Registration tournamentId={tournamentId} onRegistrationUpdate={load}/>}
                <NewPlayer tournamentId={tournamentId} onRegistrationUpdate={load} />
                <div>
                    <h6>Players</h6>
                    <div className="t-vv-players ">
                        <div className="t-vv-players-header">Name</div>
                        <div className="t-vv-players-header">Faction</div>
                    </div>
                    { players?.map((player) => <div className="t-vv-players ">
                        <div className="t-vv-players-name">{isEditable && <i className="fa-solid fa-trash t-vv-players-delete" onClick={(e) => removePlayer(player.id)}></i>}{player.name}</div>
                        <div className="t-vv-players-name">{player.faction}</div>
                    </div>)
                    }
                </div>
            </div>
        </>
    }

}

export default Players;