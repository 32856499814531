import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import Calendar from "react-calendar";
import {doRestCall} from "../../../../AppUtils";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";

const GameDate = ({dateTime, gameId, gameMasterId}) => {

    const [isEdit, setIsEdit] = useState(false)
    const [value, setValue] = useState()

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    useEffect(() => {
        setValue(dateTime)
    }, [dateTime])

    const onChange = (value) => {
        doRestCall('/game/update/date', 'put', null, {
            gameId: gameId,
            date:  value
        }, () => setValue(moment(value).format('yyyy-MM-DD')))
    };

    return <>
        {!isEditable && value && <div className="g-v-info-cell" >{moment(value).format('DD MMM YYYY')} </div>}

        {isEditable && !isEdit && value && <div className="g-v-info-cell" onClick={() => setIsEdit(true)}>{moment(value).format('DD MMM YYYY')}
            <i className="fa-solid fa-calendar g-v-info-calendar" onClick={() => setIsEdit(true)}></i>
        </div>}

        {isEditable && !isEdit && !value && <div className="g-v-info-cell" onClick={() => setIsEdit(true)}>Select Date
            <i className="fa-solid fa-calendar g-v-info-calendar" onClick={() => setIsEdit(true)}></i>
        </div>}

        {isEditable && isEdit &&  <span onBlur={() => setIsEdit(false)}>
                <Calendar defaultValue={dateTime} value={value} onChange={(value) => {onChange(value); setIsEdit(false); }} />
            </span> }
    </>
}

export default GameDate;