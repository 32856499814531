import React, {useEffect, useState} from 'react';
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useTournamentContext} from "../TournamentContext";

const GameDeploy = ({gameId}) => {

    const [deploys, setDeploys] = useState([])
    const [selectedDeploy, setSelectedDeploy] = useState()

    const {isEditable} = useTournamentContext();

    useEffect(() => {
        doRestCallAnonymous("/anonymous/deploys", 'get', null, null,
            (response) => setDeploys(response.body.deploys))
    }, [gameId])

    useEffect(() => {
        if (gameId !== null && gameId !== undefined) {
            doRestCallAnonymous("/anonymous/deploy/game/" + gameId, 'get', null, null,
                (response) => setSelectedDeploy(response.body))
        }
    }, [deploys])

    function assign(value) {
        doRestCall("/game/deploy/assign", "put", null, {
            gameId: gameId,
            deployId: value
        })
    }


    if (isEditable) {
        return <>
            <div className="c-c-hq-games-name">

                <select className="t-vv-select" onChange={(e) => assign(e.target.value)}>
                    <option value="">None</option>
                    {deploys?.sort((a, b) => a.name.localeCompare(b.name))
                        .map(deploy => <option selected={deploy.id === selectedDeploy?.id}
                                               value={deploy.id}>{deploy.name}</option>)
                    }
                </select>
            </div>
        </>
    } else {
        return <>
            <div className="c-c-hq-games-name">
                {selectedDeploy && selectedDeploy.name}
            </div>
        </>
    }

}

export default GameDeploy;