
import React, {useEffect, useRef, useState} from 'react';
import './HamburgerMenu.css';

const HamburgerMenu = () => {
    const divRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [])

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div ref={divRef} className="hamburger-menu">
            {!isOpen && <i className="fa-solid fa-bars" onClick={toggleMenu}></i>}
            {isOpen && <i className="fa-solid fa-xmark" onClick={toggleMenu}> </i>}
            {isOpen && (
                <div className="menu-content">
                    <a href="/user/logout">Logout</a>
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;

