import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import PickRandomMissionButton from "./PickRandomMissionButton";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import PickMission from "./PickMission";

const GameMission = ({gameId, gameMasterId}) => {


    const {currentUser} = useCurrentUserContext();

    const [mission, setMission] = useState();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    }, [currentUser, gameMasterId])

    function loadAssignedMission() {
        doRestCall("/anonymous/mission/game/" + gameId, "get", null, null,
            (response) => setMission(response.body));
    }

    useEffect(() => {
        if (gameId !== undefined && gameId !== null) {
            loadAssignedMission();
        }
    }, [gameId])

    return <>

        {!mission && isEditable &&
            <div className="row c-c-hq-row">
                <div className="col-lg-8">
                    <div className="g-v-mission">
                        <h6>Mission</h6>
                        <PickMission gameId={gameId} gameMasterId={gameMasterId} reload={loadAssignedMission}
                                     selectedMission={mission}/>
                        <PickRandomMissionButton gameId={gameId} gameMasterId={gameMasterId}
                                                 reload={loadAssignedMission}/>
                    </div>
                </div>
            </div>
        }
        {mission &&
            <div className="row c-c-hq-row">
                <div className="col-lg-8">
                    <div className="g-v-mission">
                        <h6>Mission</h6>
                        <PickMission gameId={gameId} gameMasterId={gameMasterId} reload={loadAssignedMission}
                                     selectedMission={mission}/>
                        <PickRandomMissionButton gameId={gameId} gameMasterId={gameMasterId}
                                                 reload={loadAssignedMission}/>
                    </div>
                    <div className="c-c-hq-mission-row">
                        <div>
                            <div className="g-v-mission-name">{mission.name}</div>
                            <div className="g-v-mission-brief">
                                <pre>{mission.brief}</pre>
                            </div>
                            <div className="g-v-mission-text">
                                <pre>{mission.text}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
}

export default GameMission;