import React, {useEffect, useState} from 'react';
import {Checkbox, Collapse, Form, List, Select, Spin} from 'antd';
import {changeHistory, loadGameSettings, settings} from "../../GameConnector";
import moment from "moment";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import {doRestCall} from "../../../AppUtils";
import Spinner from "../../../_components/Spinner";

const Settings = ({gameId}) => {

    const [settings, setSettings] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (gameId !== undefined && gameId !== null) {
            refresh()
        }
    }, [])

    function refresh() {
        setLoading(true)
        doRestCall('/game/' + gameId + '/settings', 'get', null, null,
            (response) => setSettings(response.body),
            null,
            () => setLoading(false))
    }

    function selected(name) {
        setLoading(true)
        doRestCall('/game/' + gameId + '/settings/' + name + '/selected', 'put', null, null,
            () => refresh(),
            null,
            () => setLoading(false))
    }

    function deselected(name) {
        setLoading(true)
        doRestCall('/game/' + gameId + '/settings/' + name + '/deselected', 'put', null, null,
            () => refresh(),
            null,
            () => setLoading(false))
    }

    return <>
{/*
        <Spinner isLoading={loading}>
*/}
            {settings
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map(item => <div>
                    {!item.selected &&
                        <div><i className="fa-regular fa-square" onClick={(e) => selected(item.name)}/> {item.label}</div>}
                    {item.selected &&
                        <div><i className="fa fa-check" onClick={(e) => deselected(item.name)}/> {item.label}</div>}
                </div>)}
{/*
        </Spinner>
*/}

    </>
}

export default Settings;