import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Analytics} from "../../GoogleAnalytics";
import {doRestCall} from "../../AppUtils";

const UserActivation = () => {

    Analytics("/user/activation", "User Activation")

    const navigate = useNavigate();

    const queryParams = new URLSearchParams(window.location.search)
    const activationId = queryParams.get("activationId")

    useEffect(() => {
        console.log("pre",  queryParams.get("activationId"));
        doRestCall('/user/activate/' + activationId, 'get', {}, null,
            (response) => navigate('/user/login'))
    }, [])

    return (
        <>

        </>
    );
};

export default UserActivation;

