import React, {useEffect, useState} from "react";
import {
    Button, Form, Input, Select,
    Table
} from "antd";

import {Analytics} from "../../GoogleAnalytics";
import {assignUnit, codexFactions, loadUnitsByFaction, tagsMy} from "../TagConnector";
import {Auth} from "../../user/auth/Auth";
import {notificationFailure} from "./AssignUnitNotifications";
import {useNavigate} from "react-router-dom";

const AssignUnit = () => {

    Analytics("/tag/assignunit", "Assign Unit")

    const [error, setError] = useState()

    const navigate = useNavigate();
    const [form] = Form.useForm();


    useEffect(() => {
        if (error !== undefined && error !== null) {
            notificationFailure(error)
        }
    }, [error])


    function onFinish(values) {

        assignUnit(values)
            .then(response => {

                if (!response.valid) {
                    setError(response.errors[0].error)
                }

                if (response.valid) {
                    navigate('/tags/my');
                }
            })
            .catch(error => {
                console.log(error)
            })
    }


    /*  factions   */


    const [factions, setFactions] = useState({})
    const [selectedFaction, setSelectedFaction] = useState({})
    const [factionOptions, setFactionOptions] = useState([])


    useEffect(() => {
        codexFactions()
            .then(response => {
                setFactions(response.body)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    useEffect(() => {

        if (factions !== undefined && factions !== null) {
            let temp = []
            for (let i = 0; i < factions.length; i++) {
                temp[i] = {
                    value: factions[i],
                    label: factions[i],
                }
            }
            setFactionOptions(temp)
        }

    }, [factions]);


    const onFactionChange = (value) => {
        setSelectedFaction(value)
    };


    /*  units   */


    const [units, setUnits] = useState({})
    const [unitOptions, setUnitOptions] = useState([])


    useEffect(() => {

        let temp = []
        for (let i = 0; i < units.length; i++) {
            temp[i] = {
                value: units[i].name,
                label: units[i].name,
            }
        }
        setUnitOptions(temp)

    }, [units])


    useEffect(() => {

        if (selectedFaction !== undefined && selectedFaction !== null) {
            loadUnitsByFaction(selectedFaction)
                .then(response => {
                    setUnits(response.body)
                })
                .catch(error => {
                    console.log(error)
                })
        }

    }, [selectedFaction])


    /* tags */


    const [tagOptions, setTagOptions] = useState([])
    const [tags, setTags] = useState([])


    const onChange = (value) => {
    };
    const onSearch = (value) => {
    };

// Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    useEffect(() => {
        tagsMy()
            .then(response => {
                setTags(response.body)
            })
            .catch(error => {
                console.log(error)
            })

    }, []);
    useEffect(() => {
        let temp = []
        for (let i = 0; i < tags.length; i++) {
            temp[i] = {
                id: tags[i].id,
                name: tags[i].id,
                value: tags[i].id,
                label: tags[i].id,
            }
        }
        setTagOptions(temp)
    }, [tags])


    /*  */


    return (
        <>
            <Auth/>

            Assign unit to tag


            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  style={{width: '100%'}}
                  className="gameEditForm"
            >

                <Form.Item name="tagId" label="Tag ID">
                    <Select
                        showSearch
                        placeholder="Select a tag"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={tagOptions}
                        style={{width: 400}}
                    />
                </Form.Item>

                <Form.Item name="unitFaction" label="Faction">
                    <Select
                        showSearch
                        placeholder="Select a faction"
                        optionFilterProp="children"
                        onChange={onFactionChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        options={factionOptions}
                        style={{width: 400}}
                    />
                </Form.Item>

                <Form.Item name="unitName" label="Unit Name">
                    <Select
                        showSearch
                        allowClear={true}
                        placeholder="Select a unit"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        filterOption={filterOption}
                        listHeight={200}
                        options={unitOptions}
                        style={{width: 400}}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{offset: 8, span: 16}}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>

            </Form>


        </>
    );
};

export default AssignUnit;

