import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {forceView} from "../ForceConnector";
import {Auth} from "../../../user/auth/Auth";
import {Button, Form, InputNumber, Popconfirm, Space, Table} from "antd";
import {Analytics} from "../../../GoogleAnalytics";
import moment from "moment";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall} from "../../../AppUtils";
import DeleteButton from "./components/DeleteButton";
import ManageButton from "./components/ManageButton";

import "./c-f-view-styles.css"
import CreateRosterButton from "./components/CreateRosterButton";
import Cards from "./components/Cards";
import Rosters from "./components/Rosters";

const ForceView = () => {

    Analytics("/crusade/force/view", "Crusade Force View")

    const navigate = useNavigate();

    let {forceId} = useParams();

    const [loading, setLoading] = useState(true)
    const [force, setForce] = useState({});
    const {currentUser} = useCurrentUserContext();

    useEffect(() => {
        load()
    }, [])

    const load = () => {
        forceView(forceId)
            .then(response => {
                setForce(response.body)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
            })
    }

    function navigateBack(e) {
        e.preventDefault()
        navigate('/crusade/forces')
    }

    const onPrint = (e) => {
        e.preventDefault()
        navigate('/crusade/force/' + force.id + '/print?print')
    };

    return (
        <>
            <Auth/>

            <div className="c-f-view-section mt-5">
                <div className="c-f-view-container">
                    <div className="c-f-view-header mb-5">
                        <h2>{force.name}</h2>
                    </div>
                    <div className="c-f-view-create-game-form">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-10">
                                <div className="form mb-5">
                                    <div className="row mb-5 d-flex justify-content-between">
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-view-text-center">Supply Limit</label>
                                                <p className="c-f-view-text-center">{force.supplyLimit}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-view-text-center">Supply Used</label>
                                                <p className="c-f-view-text-center">{force.supplyUsed}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-view-text-center">Battle Tally</label>
                                                <p className="c-f-view-text-center">{force.battleTally}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-view-text-center">Victories</label>
                                                <p className="c-f-view-text-center">{force.victories}</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label c-f-view-text-center">Requisition
                                                    Points</label>
                                                <p className="c-f-view-text-center">{force.reqPoints}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <Cards force={force} />
                                    <Rosters forceId={forceId} />
                                    {force.notes && <div className="mb-4 mt-4">
                                        <div className="row">
                                            <div className="col-md-3 c-f-view-text-left">
                                                <label className="form-label">Notes: </label>
                                                <p>{force.notes}</p>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="row d-flex justify-content-between">
                                    <div className="col-auto">
                                        <div className="c-f-view-tlist-btn" onClick={(e) => navigateBack(e)}>Back</div>
                                    </div>
                                    <div className="col-auto">
                                        <button className="c-f-view-tlist-btn" onClick={(e) => onPrint(e)}>Print</button>
                                        <ManageButton ownerId={force.ownerId} forceId={forceId} />
                                        <DeleteButton ownerId={force.ownerId} forceId={forceId} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForceView;

