import React, {useEffect, useState} from "react";


const StateCurrent = ({value}) => {

    return (
        <>
            {value ? <div className="c-c-hq-arrow">&rarr;</div> : <div className="c-c-hq-arrow"></div>}
        </>
    );
};

export default StateCurrent;

