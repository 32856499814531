import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../../../AppUtils";


const StateNotify = ({notifyAlliances, stateName, stateDueDate}) => {

    function notify(e) {
        e.preventDefault()

        doRestCall('/campaignhq/alliance/notify', 'post', null, {
            stateName: stateName,
            stateDueDate: stateDueDate
        })
    }

    return (
        <>
            {notifyAlliances ?
                <div><i className="fa-solid fa-check"></i>
                    <button className="c-c-hq-state-notify-test" type="link" onClick={(e) => {notify(e)}}>test</button>
                </div> :
                <i className="fa-regular fa-square"></i>}
        </>
    );
};

export default StateNotify;

/*
<i className="fa-brands fa-discord"></i>*/
