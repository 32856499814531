import React, {useEffect, useState} from 'react';
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useTournamentContext} from "../TournamentContext";

const GameRule = ({gameId}) => {

    const [rules, setRules] = useState([])
    const [selectedRule, setSelectedRule] = useState()


    const {isEditable} = useTournamentContext();

    useEffect(() => {
        doRestCallAnonymous("/anonymous/rules", 'get', null, null,
            (response) => setRules(response.body.rules))
    }, [gameId])

    useEffect(() => {
        if (gameId !== null && gameId !== undefined) {
            doRestCallAnonymous("/anonymous/rule/game/" + gameId, 'get', null, null,
                (response) => setSelectedRule(response.body))
        }
    }, [rules])


    function assign(value) {
        doRestCall("/game/rule/assign", "put", null, {
            gameId: gameId,
            ruleId: value
        })
    }

    if(isEditable) {
        return <>
            <div className="c-c-hq-games-name">
                <select className="t-vv-select" onChange={(e) => assign(e.target.value) }>
                    <option value="">None</option>
                    {rules?.sort((a, b) => a.name.localeCompare(b.name))
                        .map(rule => <option selected={rule.id === selectedRule?.id} value={rule.id} >{rule.name}</option>)
                    }
                </select>
            </div>
        </>
    } else {
        return <>
            <div className="c-c-hq-games-name">
                {selectedRule && selectedRule.name}
            </div>
            </>
    }


}

export default GameRule;