import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const GameName = ({name, gameMasterId, gameId}) => {

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    const onChange = (value) => {
        doRestCall('/game/update/name', 'put', null, {
            gameId: gameId,
            name:  value
        })
    };

    return <>
        {isEditable && <input className="g-v-info-input-number" defaultValue={name} onChange={(e) => onChange(e.target.value)} /> }
        {!isEditable && <div className="g-v-info-cell">{name}</div> }
    </>
}

export default GameName;