import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";
import {TournamentStage} from "../../config";
import moment from "moment/moment";
import StageDate from "./StageDate";

const StageEnd = ({tournamentId, currentStage, stages}) => {
    const navigate = useNavigate();

    const [stage, setStage] = useState();

    useEffect(() => {
        let filter = stages?.filter(s => s.name === TournamentStage.ENDED )[0];
        setStage(filter)
    }, [stages])

    function markActive() {
        return TournamentStage.ENDED === currentStage
    }

    function markPassed() {
        return [TournamentStage.ENDED].includes(currentStage)
    }

    function onChange(value) {
        doRestCall('/tournament/update/enddate', 'put', null, {
            tournamentId: tournamentId,
            endDate:  value
        })
    }

    return <>
        <li className={markPassed() ? "t-vv-state-past" : "t-vv-state-future"}>
            <div className={markActive() ? "t-vv-state-cell-active" : "t-vv-state-cell"}>{stage?.label}</div>
{/*
            <div className="t-vv-state-cell">{stage && stage?.dateTime && moment(stage.dateTime).format('DD MMM YYYY h:mm a')}</div>
*/}
            <StageDate tournamentId={tournamentId} dateTime={stage?.dateTime} onValueChange={onChange}/>
        </li>
    </>
}

export default StageEnd;