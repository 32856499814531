import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const Models = ({rosterId, unitId, isSingleModelUnit, models, reloadRoster}) => {

    function clicked(e) {
        e.preventDefault()

            doRestCall('/roster/unit/warlord',
                'put', null, {
                    rosterId: rosterId,
                    unitId: unitId,
                    value: true
                })
    }

    function updateModelCount(modelId, count) {
        doRestCall('/roster/unit/model/count', 'put', null, {
            rosterId: rosterId,
            unitId: unitId,
            modelId: modelId,
            count: count
        }, () => reloadRoster())
    }

    /*<input type="number" placeholder="" min={0} defaultValue={card?.points} onBlur={(e) => onPointsUpdate(e)}*/
    return (
        <>
            {!isSingleModelUnit && models?.map(model =>
                <div className="r-manage-unit-model-name">
                    <input key={model?.id} className="r-manage-input-number" type="number" placeholder="" defaultValue={model?.count} min={0} onBlur={(e) => updateModelCount(model.id, e.target.value) } />
                    {model?.name}
                </div>)
            }
        </>
    );
};

export default Models;

