import React, {useEffect, useState} from "react";
import {DatePicker, Modal, Space} from "antd";
import {doRestCall} from "../../../../../AppUtils";
import dayjs from "dayjs";
import StateName from "./StateName";
import StateNotify from "./StateNotify";
import StateCurrent from "./StateCurrent";


const State = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);


    const [modalStateId, setModalStateId] = useState()
    const [modalStateName, setModalStateName] = useState()
    const [modalStateDueDate, setModalStateDueDate] = useState()

    const [states, setStates] = useState([])

    function reload() {
        doRestCall('/campaignhq/state/all', 'get', null, null,
            (response) => {
                setStates(response.body);
            })
    }

    useEffect(() => {
        reload();
    }, [])

    function onCreate(e) {
        e.preventDefault()
        setIsModalOpen(true)

        setModalStateId(undefined)
        setModalStateName(undefined)
        setModalStateDueDate(undefined)
    }

    function onEdit(e, id, name, dueDate) {
        e.preventDefault()
        setIsModalOpen(true)

        console.log(id, name, dueDate)

        setModalStateId(id)
        setModalStateName(name)
        setModalStateDueDate(dayjs(dueDate))
    }

    function onOk() {
        setIsModalOpen(false)

        doRestCall('/campaignhq/state/create', 'post', null, {
            campaignHqId: "0",
            name: modalStateName,
            dueDate: modalStateDueDate.format('YYYY-MM-dd HH:mm:ss'),
        }, (response) => {
            setModalStateId(undefined)
            setModalStateName(undefined)
            setModalStateDueDate(undefined)
            reload();
        })
    }

    function onCancel() {
        setIsModalOpen(false)

        setModalStateId(undefined)
        setModalStateName(undefined)
        setModalStateDueDate(undefined)
    }

    return (
        <>
            <Modal title="Create State" open={isModalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
                <Space direction="horizontal">
                    <label>Name</label>
                    <input type="text" defaultValue={modalStateName} placeholder="Name" onChange={(e) => setModalStateName(e.target.value)}/>
                    <DatePicker
                        showTime
                        value={modalStateDueDate}
                        onChange={(value, dateString) => {
                            setModalStateDueDate(dateString)
                        }}
                    />
                </Space>
            </Modal>

            <div className="row c-c-hq-row"  >
                <div className="col-sm-6">
                    <div className="c-c-hq-state-title">
                        <div className="c-c-hq-state-title-name"><h6>State</h6></div>
                        <div className="c-c-hq-state-title-icon">
                            <i className="fa-solid fa-plus" onClick={(e) => onCreate(e)}></i>
                        </div>
                    </div>
                    <div className="c-c-hq-state ">
                        <div className="c-c-hq-state-name-header">Name</div>
                        <div className="c-c-hq-state-date-header">Due Date</div>
                        <div className="c-c-hq-state-date-header">Notify</div>
                    </div>

                    {states?.map((state) => <div className="c-c-hq-state ">
                            <div className="c-c-hq-state-cell "><StateCurrent value={state.current}/> <StateName
                                value={state.name}/></div>
                            <div className="c-c-hq-state-date ">{dayjs(state.dueDate).format('DD-MMM-YYYY HH:mm')}</div>
                            <div className="c-c-hq-state-notify ">
                                <StateNotify notifyAlliances={state.notifyAlliances} stateDueDate={state.dueDate}
                                             stateName={state.name}/>
                            </div>
                            <div className="c-c-hq-state-icon "><i className="fa-regular fa-trash-can"></i></div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default State;

