import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../../../AppUtils";


const AllianceDiscord = ({allianceId, discordChannel, reloadAlliances}) => {

    const [hidden, setHidden] = useState(false);

    function onSquareClick(e) {
        e.preventDefault();
        setHidden(true);
    }

    function onIconClick(e) {
        e.preventDefault();
        setHidden(true);
    }

    function onInputBlur(e) {
        e.preventDefault();

        setHidden(false)

        doRestCall('/campaignhq/alliance/update/discord', 'put', null, {
            allianceId: allianceId,
            discordChannel: e.target.value,
        }, () => reloadAlliances())
    }

    return (
        <>
            { discordChannel && <div className="c-c-hq-alliance-discord-icon" hidden={hidden}>
                <i className="fa-brands fa-discord " onClick={(e) => onIconClick(e)}></i>
            </div> }

            { !discordChannel && <div className="c-c-hq-alliance-discord-icon" hidden={hidden}>
                <i className="fa-regular fa-square" onClick={(e) => onSquareClick(e)}></i>
            </div> }

            <div hidden={!hidden}>
                <input className="c-c-hq-alliance-discord-input" defaultValue={discordChannel} onBlur={(e) => onInputBlur(e)} />
            </div>
        </>
    );
};

export default AllianceDiscord;

