import React, {useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useNavigate} from "react-router-dom";
import {Button, Popconfirm} from "antd";
import {doRestCall} from "../../../AppUtils";
import Spinner from "../../../_components/Spinner";
import Factions from "./Factions";

const RegistrationAlreadyRegistered = ({ tournamentId, onRegistrationUpdate }) => {

    const [loading, setLoading] = useState(false)

    function unregister() {
        setLoading(true)
        doRestCall('/tournament/' + tournamentId + '/unregister' , 'post', null, null,
            (response) => {
                onRegistrationUpdate()
            },
            null,
            () => {
                setLoading(false)
            })
    }

    return <>
            <div className="t-v-line2"></div>
            <div className="t-v-join-info-ch">
                <div className="mb-3">
                    <p>You are already registered</p>
                </div>
                <div className="mb-3">
                    <div className="col-auto">
                        <button className="t-vv-registration-button" onClick={() => {unregister()}}>Unregister</button>
                    </div>
                </div>
            </div>
    </>
}

export default RegistrationAlreadyRegistered;