import React, {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {registerUser} from "../UserConnector";
import {useNavigate} from "react-router-dom";

import {notificationFailure} from "../UserNotifications";
import {Analytics} from "../../GoogleAnalytics";
import Link from "antd/lib/typography/Link";
import {useCurrentUserContext} from "../CurrentUserContext";
import {doRestCall} from "../../AppUtils";
import Errors from "./components/Errors";

import "./styles-u-r.css"
import Spinner from "../../_components/Spinner";

const UserRegistration = () => {

    Analytics("/user/registration", "User Registration")

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [errors, setErrors] = useState(undefined)

    const [loading, setLoading] = useState(false)

    const { ref } = useCurrentUserContext();

    useEffect(() => {
        form.setFieldsValue({
            ref: ref
        })
    }, [])


    function onSuccess(response) {
        if (response.valid) {
            navigate('/user/login')
        }
        if (!response.valid) {
            if (!response.valid) {
                setErrors(response.errors)
            }
        }
    }

    function onFinish(values) {
        setLoading(true)
        doRestCall( '/user/register', 'post', {}, {
            username: values.username,
            password: values.password,
            email: values.email,
            ref: values.ref
        }, onSuccess, null, () => setLoading(false))
    }

    function navigateToLogin() {
        navigate("/user/login")
    }

    return (
        <>

            <Form id={"form"}
                  onFinish={onFinish}
                  form={form}
                  className="u-r-registerForm"
                  layout="vertical"
            >

                <h1 className="u-r-signUpHeader">Sign Up</h1>
                <h4 className="u-r-signUpSubheader">Please enter your details below</h4>

                <Errors errors={errors}/>

                <Form.Item name="ref" id="ref" hidden="true">
                    <Input/>
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please input username!',
                        },
                    ]}
                    label="Username"
                    name="username">
                    <Input placeholder="Input Username" className="u-r-registerInput" name="username"
                           data-cy="username"/>
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Please input email!',
                        },
                    ]}
                    label="Email"
                    name="email">
                    <Input placeholder="Input Email" className="u-r-registerInput" name="email" data-cy="email"/>
                </Form.Item>
                <div className="u-r-activation-note">You will receive a link to activate your account via email</div>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please input password!',
                        },
                    ]}
                    label="Password"
                    name="password">
                    <Input.Password placeholder="Input Password" className="u-r-registerInput" name="password"
                                    data-cy="password"/>
                </Form.Item>

                <Form.Item
                    dependencies={['password']}
                    rules={[
                        {
                            required: true,
                            message: 'Please input password!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The new password that you entered do not match!'));
                            },
                        }),
                    ]}
                    label="Confirm Password"
                    name="passwordRepeat">
                    <Input.Password placeholder="Repeat Password" className="u-r-registerInput" name="passwordRepeat"
                                    data-cy="passwordRepeat"/>
                </Form.Item>

                <p className="u-r-hasAccountLabel">
                    Already have an account?
                    <span className="u-r-hasAccountAction"><Link onClick={navigateToLogin}>Sign In</Link></span>
                </p>
                <Spinner isLoading={loading}>
                    <Button htmlType="submit" name="submit" data-cy="submit">
                        Sign Up
                    </Button>
                </Spinner>

            </Form>
        </>
    );
};

export default UserRegistration;

