import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";
import WarlordMark from "./WarlordMark";
import WarlordButton from "./WarlordButton";
import Weapons from "./Weapons";
import CloneButton from "./CloneButton";
import CloneRemoveButton from "./CloneRemoveButton";
import Models from "./Models";
import Prices from "./Prices";

const Unit = ({rosterId, unit, setSummary, reloadRoster, hideWeapons}) => {

    const [selected, setSelected] = useState(false)
    const [warlord, setWarlord] = useState(false)

    useEffect(() => {
        setSelected(unit.selected)
        setWarlord(unit.warlord)
    }, [unit])

    function addToRoster(e) {
        e.preventDefault()

        doRestCall('/roster/unit/select',
            'put', null, {
                rosterId: rosterId,
                unitId: unit.id
            }, (response) => {
                setSummary(response.body.summary)
                setSelected(response.body.selected)
                reloadRoster()
            })
    }

    function removeFromRoster(e) {
        e.preventDefault()

        doRestCall('/roster/unit/deselect',
            'put', null, {
                rosterId: rosterId,
                unitId: unit.id
            }, (response) => {
                setSummary(response.body.summary)
                setSelected(response.body.selected)
                reloadRoster()
            })
    }

    return (
        <>
            <div>
                <div className="r-manage-unit-name">

                    <WarlordMark warlord={warlord}/>
                    {selected && <i className="fa fa-check r-manage-unit-select" onClick={(e) => removeFromRoster(e)}></i>}
                    {!selected && <i className="fa-regular fa-square r-manage-unit-selectbox" onClick={(e) => addToRoster(e)}></i>}
                    {unit?.name}
                    <Prices prices={unit?.prices} />

                    <WarlordButton rosterId={rosterId}
                            unitId={unit.id}
                            warlord={warlord}
                            canBeWarlord={unit.canBeWarlord}
                            setWarlord={setWarlord} />

                    <CloneButton rosterId={rosterId}
                                 unitId={unit.id}
                                 cloned={unit.cloned}
                                 reloadRoster={reloadRoster} />

                    <CloneRemoveButton rosterId={rosterId}
                                       unitId={unit.id}
                                       cloned={unit.cloned}
                                       reloadRoster={reloadRoster} />

                </div>
                <Models rosterId={rosterId} unitId={unit.id} isSingleModelUnit={unit.isSingleModelUnit} models={unit.models} reloadRoster={reloadRoster} />
                <Weapons rosterId={rosterId} unitId={unit.id} weapons={unit.weapons} hideWeapons={hideWeapons} />
            </div>
        </>
    );
};

export default Unit;

