import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth} from "../../user/auth/Auth";
import {Button, Form, Popconfirm, Popover, Space, Table, Typography} from "antd";
import "./styles-t-view.css"
import {Analytics} from "../../GoogleAnalytics";
import TournamentStarted from "./TournamentStarted";
import TournamentRegistrationOpen from "./TournamentRegistrationOpen";
import TournamentRegistrationClosed from "./TournamentRegistrationClosed";
import TournamentEnded from "./TournamentEnded";
import TournamentCreated from "./TournamentCreated";
import Status from "./components/Status";
import Details from "./components/Details";
import {doRestCall} from "../../AppUtils";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";
import Spinner from "../../_components/Spinner";

const TournamentViewRegistered = () => {

    Analytics("/tournament/view", "Tournament View")

    let {tournamentId} = useParams();
    const navigate = useNavigate();

    const {currentUser} = useCurrentUserContext();
    const [loading, setLoading] = useState(true)
    const [tournament, setTournament] = useState({});
    const {Title} = Typography;

    useEffect(() => {
        load()
    }, [])


    function onViewSuccess(response) {
        setTournament(response.body)
        setLoading(false)
    }

    const load = () => {
        setLoading(true)
        doRestCall('/tournament/view/' + tournamentId, 'get', null, null,
            onViewSuccess,
            null,
            () => setLoading(false))
    }

    function navigateBack() {
        navigate("/tournaments")
    }

    return (
        <>
            <Auth/>

            <Spinner isLoading={loading} >
            <div className="t-v-mainheading-section">
                {(tournament && tournament.status === 'REGISTRATION_OPENED') &&
                    <TournamentRegistrationOpen tournament={tournament}/>}
                {(tournament && tournament.status === 'REGISTRATION_CLOSED') &&
                    <TournamentRegistrationClosed tournament={tournament}/>}
                {(tournament && tournament.status === 'STARTED') && <TournamentStarted tournament={tournament}/>}
                {(tournament && tournament.status === 'CREATED') && <TournamentCreated tournament={tournament}/>}
                {(tournament && tournament.status === 'ENDED') && <TournamentEnded tournament={tournament}/>}

                <div className="row mb-3 justify-content-between t-v-buttons-container">
                    <div className="col-auto">
                        <button className="t-v-tlist-btn" onClick={navigateBack}>Back</button>
                    </div>
                    <div className="col-auto">
                        {(currentUser?.role === 'ADMIN' || currentUser?.id === tournament.ownerId || tournament.tournamentMasterId)
                            && <Space direction={"horizontal"}>
                                <EditButton ownerId={tournament.ownerId}
                                            tournamentId={tournament.id}
                                            tournamentMasterId={tournament.tournamentMasterId}/>
                                <DeleteButton tournamentId={tournament.id}
                                              ownerId={tournament.ownerId}/>
                            </Space>
                        }
                    </div>
                </div>
            </div>
            </Spinner>


        </>
    );
};

export default TournamentViewRegistered;

