import React, {useEffect, useState} from 'react';
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useTournamentContext} from "../TournamentContext";

const GameMission = ({gameId}) => {

    const [missions, setMissions] = useState([])
    const [selectedMission, setSelectedMission] = useState()

    const {isEditable} = useTournamentContext();

    useEffect(() => {
        doRestCallAnonymous("/anonymous/missions", 'get', null, null,
            (response) => setMissions(response.body.missions))
    }, [gameId])

    useEffect(() => {
        if (gameId !== null && gameId !== undefined) {
            doRestCallAnonymous("/anonymous/mission/game/" + gameId, 'get', null, null,
                (response) => setSelectedMission(response.body))
        }
    }, [missions])

    function assign(value) {
        doRestCall("/game/mission/assign", "put", null, {
            gameId: gameId,
            missionId: value
        })
    }

    if (isEditable) {
        return <>
            <div className="c-c-hq-games-name">
                <select className="t-vv-select" onChange={(e) => assign(e.target.value) }>
                    <option value="">None</option>
                    {missions?.sort((a, b) => a.name.localeCompare(b.name))
                        .map(mission => <option selected={mission.id === selectedMission?.id} value={mission.id} >{mission.name}</option>)
                    }
                </select>
            </div>
        </>
    } else {
        return <>
            <div className="c-c-hq-games-name">
                {selectedMission?.name}
            </div>

        </>
    }



}

export default GameMission;