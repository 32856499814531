import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Popconfirm} from "antd";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const DeletePlayerButton = ({gameId, gameMasterId, reload, playerId}) => {

    const navigate = useNavigate();

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    function onConfirmDelete() {
        doRestCall('/game/update/participant/remove', 'put', null, {
            gameId: gameId,
            participantId: playerId
        },
            () => {
                reload()
            });
    }

    return (
        <>
            {isEditable &&
                <span className="g-v-popconfirm">
                    <Popconfirm
                        title={"Delete game"}
                        description={"Are you sure you want to delete this player?"}
                        onConfirm={onConfirmDelete}>
                        <button className="g-v-info-delete-button">Delete Player</button>
                    </Popconfirm>
                </span>
            }
        </>
    );
};

export default DeletePlayerButton;