import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";
import Adjustment from "./Adjustment";


const Adjustments = ({rosterId, adjustments, reloadRoster}) => {

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(adjustments)
    }, [adjustments]);

    function createAdjustment() {
        doRestCall('/roster/adjustment/create', 'post', null, {
            rosterId: rosterId,
        }, (response) => {reloadRoster()})
    }

    return (
        <>
            <div className="r-manage-adjustments">
                Adjustments: <button className="r-manage-adjustment-button" type="link" onClick={() => {createAdjustment()}}>Add</button>
                {list?.map(adjustment => <Adjustment
                    rosterId={rosterId}
                    value={adjustment?.value}
                    adjustmentId={adjustment?.id}
                    description={adjustment?.description}
                    reloadRoster={reloadRoster} />
                )}
            </div>
        </>
    );
};

export default Adjustments;

