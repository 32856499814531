import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import Factions from "./Factions";
import {useTournamentContext} from "../TournamentContext";

const NewPlayer = ({tournamentId, onRegistrationUpdate}) => {

    const {isEditable} = useTournamentContext();

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedFaction, setSelectedFaction] = useState();
    const [universe, setUniverse] = useState()

    useEffect(() => {
        if (tournamentId !== null && tournamentId !== undefined) {
            doRestCall("/tournament/users", 'get', null, null,
                (response) => {
                    setUsers(response.body.users);
                })
        }
    }, [tournamentId])

    useEffect(() => {
        if (tournamentId !== null && tournamentId !== undefined) {
            doRestCall("/tournament/" + tournamentId + "/universe", 'get', null, null,
                (response) => {
                    setUniverse(response.body.universe);
                })
        }
    }, [tournamentId])

    function add() {
        doRestCall("/tournament/update/participant", 'put', null, {
            tournamentId: tournamentId,
            participantId: selectedUser,
            state: true,
            faction: selectedFaction
        },
            (response) => {onRegistrationUpdate()})
    }

    if (isEditable) {
        return <>
            <div className="t-vv-newplayer">
                <div>Add Player</div>
                <select className="t-vv-select t-vv-newplayer-user" onChange={(e) => setSelectedUser(e.target.value)}>
                    <option value="">None</option>
                    {users?.sort((a, b) => a.username.localeCompare(b.username))
                        .map(user => <option value={user.userId}>{user.username}</option>)
                    }
                </select>
                <div className="t-vv-newplayer-faction">
                    <Factions universe={universe} setSelectedFaction={setSelectedFaction}/>
                </div>
                <button className="t-vv-add-user-button" onClick={() => {
                    add()
                }}>Add
                </button>
            </div>
        </>
    }
}

export default NewPlayer;