import React, {useEffect, useState} from 'react';
import {Form, Select, Spin} from 'antd';
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import {useNavigate} from "react-router-dom";
import Spinner from "../../../_components/Spinner";
import {TournamentStage} from "../../config";
import moment from "moment/moment";
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {useTournamentContext} from "../TournamentContext";

const StageRounds = ({currentStage, stages, tournamentId, setSelectedRound}) => {
    const navigate = useNavigate();

    const [stage, setStage] = useState();

    const [rounds, setRounds] = useState()

    const {isEditable} = useTournamentContext();

    function loadRounds() {
        doRestCallAnonymous('/anonymous/tournament/' + tournamentId + '/rounds', 'get', null, null,
            (response) => {
                setRounds(Array.from({length: response.body.rounds}, (_, i) => i + 1))
            })
    }

    useEffect(() => {
        if (tournamentId !== null && tournamentId !== undefined) {
            loadRounds();
        }
    }, [tournamentId]);

    useEffect(() => {
        let filter = stages?.filter(s => s.name === TournamentStage.ENDED )[0];
        setStage(filter)
    }, [stages])

    function generateNextRound() {
        doRestCall('/tournament/' + tournamentId + '/nextround', 'post', null, null,
            (response) => {
                loadRounds()
            })
    }

    function markActive() {
        return TournamentStage.ENDED === currentStage
    }

    function onRoundSelected(round) {
        setSelectedRound(round)
    }

    return <>
        {rounds?.map((round) => <li className="t-vv-state-empty">
                <div className={markActive() ? "t-vv-state-cell-active" : "t-vv-state-cell"} onClick={() => onRoundSelected(round)}>Round {round}</div>
            </li>)}
        {isEditable && <div><button className="t-vv-stage-start-button" onClick={(e) => generateNextRound()}>Generate Next Round</button></div> }
        {isEditable && <div><button className="t-vv-stage-start-button">End</button></div> }
    </>
}

export default StageRounds;