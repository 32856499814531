export const TournamentStatus = {
    CREATED: "CREATED",
    REGISTRATION_OPENED: "REGISTRATION_OPENED",
    REGISTRATION_CLOSED: "REGISTRATION_CLOSED",
    STARTED: "STARTED",
    ENDED: "ENDED"
}


export const RegistrationStatus = {
    REGISTERED: "REGISTERED",
    NOT_REGISTERED: "NOT_REGISTERED"
}


export const TournamentStage = {
    CREATED: "CREATED",
    REGISTRATION_OPENED: "REGISTRATION_OPENED",
    REGISTRATION_CLOSED: "REGISTRATION_CLOSED",
    STARTED: "STARTED",
    ENDED: "ENDED",
    DELETED: "DELETED",
}