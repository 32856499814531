import React, {useEffect, useState} from "react";
import {DatePicker, Modal, Space} from "antd";
import {doRestCall} from "../../../../../AppUtils";
import AllianceName from "./AllianceName";


const State = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [modalAllianceName, setModalAllianceName] = useState()

    const [alliances, setAlliances] = useState([])

    function reload() {
        doRestCall('/campaignhq/alliance/all', 'get', null, null,
            (response) => {
                setAlliances(response.body);
            })
    }

    useEffect(() => {
        reload();
    }, [])

    function onCreate(e) {
        e.preventDefault()
        setIsModalOpen(true)

        setModalAllianceName(undefined)
    }

    function onOk() {
        setIsModalOpen(false)

        doRestCall('/campaignhq/alliance/create', 'post', null, {
            campaignHqId: "0",
            name: modalAllianceName,
        }, (response) => {
            setModalAllianceName(undefined)
            reload();
        })
    }

    function onCancel() {
        setIsModalOpen(false)

        setModalAllianceName(undefined)
    }

    return (
        <>
            <Modal title="Create Alliance" open={isModalOpen} onOk={() => onOk()} onCancel={() => onCancel()}>
                <Space direction="horizontal">
                    <label>Name</label>
                    <input type="text" defaultValue={modalAllianceName} placeholder="Name" onChange={(e) => setModalAllianceName(e.target.value)}/>
                </Space>
            </Modal>

            <div className="col-sm-4 col-5">
                    <div className="c-c-hq-alliance-title-name"><h6>Alliances</h6></div>
                    <div className="c-c-hq-alliance-title-icon">
                        <i className="fa-solid fa-plus" onClick={(e) => onCreate(e)}></i>
                    </div>

                    <div className="c-c-hq-alliance "><div className="c-c-hq-text c-c-hq-alliance-name-header">Name</div> <div className="c-c-hq-alliance-players-header">Players</div> <div className="c-c-hq-alliance-score-header">Score</div></div>

                    {alliances?.map(alliance => <div className="c-c-hq-alliance ">
                        <AllianceName value={alliance.name}  discordChannel={alliance.discordChannel} allianceId={alliance.id} reloadAlliances={reload} />
                        <div className="c-c-hq-alliance-players">{alliance.players}</div>
                        <div className="c-c-hq-alliance-score">{alliance.score}</div>
                        <div className="c-c-hq-alliance-icon "><i className="fa-regular fa-trash-can"></i></div>
                    </div>)}
                </div>
        </>
    );
};

export default State;

