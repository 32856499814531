import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const Factions = ({reloadRoster, rosterId, preSelectedFaction}) => {

    const [factions, setFactions] = useState([]);

    useEffect(() => {
        doRestCall('/roster/codex/factions', 'get', null, null,
            (response) => setFactions(response.body.factions))
    }, [])

    function onChange(faction) {
        doRestCall('/roster/' + rosterId + '/faction/' + faction,'put', null, null,
            () => reloadRoster())
    }

    return (
        <>
            <div className="r-manage-factions">
                Factions:
               <select className="r-manage-factions-select" onChange={(e) => onChange(e.target.value)}>
                    <option value="">None</option>
                    {factions?.map(faction =>
                        <option selected={preSelectedFaction && preSelectedFaction === faction.id} value={faction.id} >{faction.name}</option>)
                    }
                </select>
            </div>
        </>
    );
};

export default Factions;

