import React, {useEffect, useState} from "react";
import {doRestCall} from "../../../AppUtils";


const Adjustment = ({rosterId, adjustmentId, description, value, reloadRoster}) => {

    const [d, setD] = useState("");
    const [id, setId] = useState("");
    const [v, setV] = useState("");

    useEffect(() => {
        setD(description)
        setId(adjustmentId)
        setV(value)
    }, [description, id, value])

    function updateValue(e) {
        doRestCall('/roster/adjustment/update/value', 'put', null, {
            rosterId: rosterId,
            adjustmentId: id,
            value: e.target.value
        }, (response) => {reloadRoster()})
    }

    function updateDescription(e) {
        doRestCall('/roster/adjustment/update/description', 'put', null, {
            rosterId: rosterId,
            adjustmentId: id,
            description: e.target.value
        }, (response) => {reloadRoster()})
    }

    function deleteAdjustment() {
        doRestCall('/roster/adjustment/delete', 'delete', null, {
            rosterId: rosterId,
            adjustmentId: id,
        }, (response) => {reloadRoster()})
    }

    return (
        <>
            <div className="r-manage-adjustment-row">
                <div className="r-manage-adjustment">
                    <textarea rows="2" className="r-manage-input-adjustment" defaultValue={d} onBlur={(e) => updateDescription(e)} />
                </div>
                <div className="r-manage-adjustment">
                    <input type="number" className="r-manage-input-number" defaultValue={v} onChange={(e) => updateValue(e)}/>
                </div>
                <div className="r-manage-adjustment">
                    <button className="r-manage-adjustment-button" type="link" onClick={() => deleteAdjustment()} >Delete</button>
                </div>
            </div>
        </>
    );
};

export default Adjustment;

