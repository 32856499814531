import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCall, doRestCallAnonymous} from "../../../AppUtils";
import GameMission from "./GameMission";
import GameRule from "./GameRule";
import GameDeploy from "./GameDeploy";
import GameLayout from "./GameLayout";
import GameTable from "./GameTable";
import GamePlayers from "./GamePlayers";

const Games = ({tournamentId, round}) => {

    const [games, setGames] = useState([])

    useEffect(() => {

        if (tournamentId !== null && tournamentId !== undefined && round !== null && round !== undefined) {
            doRestCallAnonymous('/anonymous/tournament/' + tournamentId + '/round/' + round + '/games', 'get', null, null,
                (response) => {
                    setGames(response.body.games)
                })
        }


    }, [tournamentId, round]);

    return <>
        <div className="col-lg-12">
            <h6>Games</h6>
            <div className="t-vv-games ">
                <div className="t-vv-games-header">Round</div>
                <div className="t-vv-games-header">Players</div>
                <div className="t-vv-games-header">Table</div>
                <div className="t-vv-games-header">Mission</div>
                <div className="t-vv-games-header">Rule</div>
                <div className="t-vv-games-header">Deploy</div>
                <div className="t-vv-games-header">Layout</div>
            </div>

            {games?.map((game) => <div className="t-vv-games ">
                    <div className="t-vv-games-round-text">{game.round}</div>
                    <GamePlayers gameId={game.gameId} tournamentId={tournamentId} />
                    <GameTable gameId={game.gameId} tournamentId={tournamentId}/>
                    <GameMission gameId={game.gameId}/>
                    <GameRule gameId={game.gameId}/>
                    <GameDeploy gameId={game.gameId}/>
                    <GameLayout gameId={game.gameId}/>
                </div>
            )}

        </div>

    </>
}

export default Games;