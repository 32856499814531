import React, {useEffect, useState} from 'react';
import {doRestCall} from "../../../../AppUtils";
import Spinner from "../../../../_components/Spinner";
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";


const GameSettings = ({gameId, gameMasterId, onSettingsChanged}) => {

    const [settings, setSettings] = useState([])
    const [loading, setLoading] = useState(false)

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])

    useEffect(() => {
        console.log("[gameId]", gameId, settings)
        if (gameId !== undefined && gameId !== null) {
            refresh()
        }
    }, [gameId])

    function refresh() {
        setLoading(true)
        doRestCall('/game/' + gameId + '/settings', 'get', null, null,
            (response) => setSettings(response.body),
            null,
            () => setLoading(false))
    }

    function selected(name) {
        setLoading(true)
        doRestCall('/game/' + gameId + '/settings/' + name + '/selected', 'put', null, null,
            () => refresh(),
            null,
            () => {
                setLoading(false)
                onSettingsChanged()
            })
    }

    function deselected(name) {
        setLoading(true)
        doRestCall('/game/' + gameId + '/settings/' + name + '/deselected', 'put', null, null,
            () => refresh(),
            null,
            () => {
                setLoading(false)
                onSettingsChanged()
            })
    }

    return <>
        <Spinner isLoading={loading}>
            <div className="col-sm-3 col-5">
                <h6>Settings</h6>
                {!isEditable && settings
                    && settings.filter(setting => setting.selected === true).length === 0
                    && <span className="g-v-info-text">None</span>}
                {!isEditable && settings
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map(item => <div>
                        {item.selected && <div className="g-v-info-text g-v-info-row"><div className="g-v-info-cell"> {item.label}</div></div> }
                    </div>)}
                {isEditable && settings
                    .sort((a, b) => a.displayOrder - b.displayOrder)
                    .map(item => <div>
                        {!item.selected &&
                            <div  className="g-v-info-text g-v-info-row"><i className="fa-regular fa-square g-v-settings-icon" onClick={(e) => selected(item.name)}/> <div className="g-v-info-cell">{item.label}</div></div>}
                        {item.selected &&
                            <div className="g-v-info-text g-v-info-row"><i className="fa fa-check g-v-settings-icon" onClick={(e) => deselected(item.name)}/><div className="g-v-info-cell">{item.label}</div></div>}
                    </div>)}
            </div>
        </Spinner>
    </>
}

export default GameSettings;