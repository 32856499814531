import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCallAnonymous} from "../../../AppUtils";
import logo from "../../../_components/images/logo-svg.svg";

const Deploy = ({name, filepath}) => {
    const [isHovering, setIsHovering] = useState(false);

    return <>
        <div
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{position: 'relative', display: 'inline-block'}}>
            {name}
            {isHovering && (<div className="t-vv-game-deploy-preview">
                <div className="title">{name}</div>
                <img className="deploy" src={filepath} alt=""/>
            </div>)}
        </div>
    </>
}

export default Deploy;