import React, {useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";
import {Auth, isAdminAuthenticated, isAnonymous, isUserAuthenticated} from "../../user/auth/Auth";
import {Button, Form, Popconfirm, Popover, Space, Table, Typography} from "antd";
import "./styles-t-view.css"
import {Analytics} from "../../GoogleAnalytics";
import TournamentStarted from "./TournamentStarted";
import TournamentRegistrationOpen from "./TournamentRegistrationOpen";
import TournamentRegistrationClosed from "./TournamentRegistrationClosed";
import TournamentEnded from "./TournamentEnded";
import TournamentCreated from "./TournamentCreated";
import Status from "./components/Status";
import Details from "./components/Details";
import {doRestCall} from "../../AppUtils";
import {useCurrentUserContext} from "../../user/CurrentUserContext";
import EditButton from "./components/EditButton";
import DeleteButton from "./components/DeleteButton";
import Spinner from "../../_components/Spinner";
import TournamentsAnonymous from "../all/TournamentsAnonymous";
import TournamentsRegistered from "../all/TournamentsRegistered";
import TournamentViewAnonymous from "./TournamentViewAnonymous";
import TournamentViewRegistered from "./TournamentViewRegistered";

const TournamentView = () => {

    const {currentUser} = useCurrentUserContext();

    return (
        <>
            {isAnonymous(currentUser) && <TournamentViewAnonymous />}
            {(isUserAuthenticated(currentUser) || isAdminAuthenticated(currentUser)) && <TournamentViewRegistered />}
        </>
    );
};

export default TournamentView;

