import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../../user/CurrentUserContext";
import {doRestCall} from "../../../../AppUtils";

const RosterLock = ({locked, gameMasterId, gameId, playerId, selectedRosterId, reload}) => {

    const {currentUser} = useCurrentUserContext();

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        setIsEditable(currentUser && ("ADMIN" === currentUser?.role || gameMasterId === currentUser?.id))
    },[currentUser, gameMasterId])


    function lock(e) {
        e.preventDefault()

        doRestCall('/game/roster/lock', 'put', null, {
                gameId: gameId,
                userId: playerId,
                rosterId: selectedRosterId
            },
            (response) => reload())

    }

    function unlock(e) {
        e.preventDefault()

        doRestCall('/game/roster/unlock', 'put', null, {
                gameId: gameId,
                userId: playerId,
                rosterId: selectedRosterId
            },
            (response) => reload())

    }


    return <>
        {isEditable && locked &&
            <i className="fa-solid fa-lock g-v-roster-lock-button " style={{color: "white"}} onClick={(e) => unlock(e)}></i>}

        {isEditable && !locked &&
            <i className="fa-solid fa-lock-open g-v-roster-lock-button" style={{color: "white"}} onClick={(e) => lock(e)}></i>}
    </>
}

export default RosterLock;