import React, {useEffect, useState} from 'react';
import {useCurrentUserContext} from "../../../user/CurrentUserContext";
import {doRestCallAnonymous} from "../../../AppUtils";
import Mission from "./Mission";

const Missions = ({}) => {

    const [missions, setMissions] = useState([]);

    useEffect(() => {
        doRestCallAnonymous("/anonymous/missions", 'get', null, null,
            (response) => setMissions(response.body.missions));
    }, [])


    return <>
        <div className="col-sm-3 col-5">
            <h6>Missions</h6>

            {missions?.map((mission) => <div className="t-vv-text t-vv-row">
                    <div className="t-vv-cell"><Mission name={mission.name} brief={mission.brief} text={mission.text} /></div>
                </div>
            )}
        </div>
    </>
}

export default Missions;